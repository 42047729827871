import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DocumentoFileQuery } from '../model/documento-file-query';
import { DocumentoFileResponse } from '../model/documento-file-response';
import { Constants } from './constants';

@Injectable({
  providedIn: 'root'
})
export class TrainingCertificateFileService {

  private employeeCertificateDownloadUrl = 'empresa/diplomaTrabajador';
  private companyCertificateDownloadUrl = 'empresa/diplomaEmpresa';

  constructor(
    private _httpClient: HttpClient) {
  }

  public getEmployeeCertificate(documento: DocumentoFileQuery): Observable<DocumentoFileResponse> {
    return this._httpClient.post<DocumentoFileResponse>(Constants.apiRoot + this.employeeCertificateDownloadUrl, documento);
  }

  public getCompanyCertificate(documento: DocumentoFileQuery): Observable<DocumentoFileResponse> {
    return this._httpClient.post<DocumentoFileResponse>(Constants.apiRoot + this.companyCertificateDownloadUrl, documento);
  }
}
