import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { TrabajadoreComponent } from './trabajadore/trabajadore.component';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { TrabajadoreActiveComponent } from './trabajadore-active/trabajadore-active.component';
import { TrabajadoreInactiveComponent } from './trabajadore-inactive/trabajadore-inactive.component';
import { TrabajadoreEditComponent } from './trabajadore-edit/trabajadore-edit.component';
import { OwlDateTimeModule } from '@busacca/ng-pick-datetime';
import { CentersListComponent } from '../auto-reversa/centers-list/centers-list.component';
import { AppoinmentConfirmationComponent } from '../auto-reversa/appoinment-confirmation/appoinment-confirmation.component';
import { TraningCertificatesComponent } from './traning-certificates/traning-certificates.component';

@NgModule({
  declarations: [TrabajadoreComponent , 
    TrabajadoreActiveComponent , 
    TrabajadoreInactiveComponent,
    CentersListComponent,
    AppoinmentConfirmationComponent,
    TrabajadoreEditComponent,
    TraningCertificatesComponent ],
  imports: [
    CommonModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    SharedModule,
    RouterModule,
    OwlDateTimeModule
  ],
  providers: [DatePipe]
})
export class TrabajadoreModule { }
