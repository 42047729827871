import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {

constructor() { }

public editDataDetails: any = [];
public subject = new Subject<any>();
private centrosSource = new  BehaviorSubject(this.editDataDetails);
currentCentros = this.centrosSource.asObservable();

private appoinmentSubject = new Subject<any>();

private companySource = new  BehaviorSubject(null);
currentCompany = this.companySource.asObservable();

changeData(respondDate: any) {
  this.centrosSource.next(respondDate);
}

setComapny(company: string) {
  this.companySource.next(company);
}

sendTrabajadoreClick() {
  this.appoinmentSubject.next();
}

getAppointmentClick(): Observable<any>{ 
  return this.appoinmentSubject.asObservable();
}

}
