import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CentrosytrabajadoresListService } from 'src/app/core/centrosytrabajadores-list.service';
import { TrainingCertificateDetailsService } from 'src/app/core/training-certificate-details.service';
import { TrainingCertificateFileService } from 'src/app/core/training-certificate-file.service';
import { Utils } from 'src/app/core/utils';
import { CentrosirabajadorItem } from 'src/app/model/centrosirabajador-item';
import { DocCentTrabResponse } from 'src/app/model/doccenttrab-response';
import { DocumentoFileQuery } from 'src/app/model/documento-file-query';
import { DocumentoFileResponse } from 'src/app/model/documento-file-response';
import { DocumentoOrderQuery } from 'src/app/model/documento-order-query';
import { NgxPaginationConfig } from 'src/app/model/ngx-pagination-config';
import { TrabajadorItem } from 'src/app/model/trabajador-item';
import { TrainingCertificateItem } from 'src/app/model/training-certificate-item';
import { TrainingCertificateItemDetails } from 'src/app/model/training-certificate-item-details';
import { TrainingCertificateDetailsItemFilter } from 'src/app/model/training-certificate-item-details-filter';
import { TrainingCertificateItemDetailsResponse } from 'src/app/model/training-certificate-item-details-response';
import { TrainingCertificateParticipant } from 'src/app/model/training-certificate-participant';
import { CommonUiService } from 'src/app/shared/services/common-ui.service';

@Component({
  selector: 'app-trn-info',
  templateUrl: './trn-info.component.html',
  styleUrls: ['./trn-info.component.scss']
})
export class TrnInfoComponent implements OnInit, OnDestroy {

  trainingCertificateDetails = new TrainingCertificateItemDetails();

  filter: TrainingCertificateDetailsItemFilter;
  isLoading = false;
  queryTypes: DocumentoOrderQuery[];
  responseList: TrainingCertificateItemDetailsResponse;
  trainingDescriptionArray: string[];

  filterEmployees: TrabajadorItem[];
  allEmployees: TrabajadorItem[];
  pageAttrResp: DocCentTrabResponse;
  centrerEmployees: CentrosirabajadorItem[];
  filterEmployee: TrabajadorItem;

  participantsList: TrainingCertificateParticipant[];
  responseFile: DocumentoFileResponse;
  paginationConfig: NgxPaginationConfig;

  certificateId: string;
  error: string;
  recCount = 0;
  recPP = 10;
  pageNum = 1;
  pageCnt = 1;
  orderBy: string;

  pageTitle = '';

  public filterForm = new FormGroup({
    fEmployeeName: new FormControl(null)
  });

  constructor(
    public _commonUiService: CommonUiService,
    private _tariningCertificateDetailsService: TrainingCertificateDetailsService,
    private _route: ActivatedRoute,
    private _trainingCertificateFileService: TrainingCertificateFileService,
    private _location: Location) { }

  ngOnInit(): void {
    this.paginationConfig = new NgxPaginationConfig();
    this.paginationConfig.itemsPerPage = this.recPP;
    this.queryTypes = this._tariningCertificateDetailsService.order_query_list;
    this.orderBy = 'NAME';
    this.queryTypes[0].direction = 'ASC';
    this.getPageAttributes();
      

    this._route.queryParams.subscribe(params => {
      const selectedItem: TrainingCertificateItem = JSON.parse(localStorage.getItem('SelectedItem'));
      if (selectedItem) {
        this.pageTitle = selectedItem.formacion_nombre;
      } else {
        localStorage.removeItem('SelectedItem');
        this._location.back();
      }
      this.certificateId = params.id;
      this.applyFilter();
    });
  }

  ngOnDestroy(): void {
    localStorage.removeItem('SelectedItem');
  }

  getPageAttributes(): void {
    var retrievedObject = localStorage.getItem('centrosSource');
    this.pageAttrResp = JSON.parse(retrievedObject);
    if (this.pageAttrResp != null) {
      if (this.pageAttrResp.webError != null) {
        this.error = this.pageAttrResp.webError.messageToUser;
      }
    }
    this.allEmployees = this.pageAttrResp.data.trabajadores.map((i) => { i.fullName = i.apellidos + ' ' + i.nombre; return i; });
    this.filterEmployees = [];
    this.allEmployees.forEach(emp => {
      this.filterEmployees.push(emp);
    });
    this.centrerEmployees = this.pageAttrResp.data.centrosTrabajadores;
    this.filterEmployee = this._tariningCertificateDetailsService.filterEmployee;
    if (this.filterEmployee) {
      this.filterForm.get('fEmployeeName').patchValue(this.filterEmployee.id);
      this.applyFilter();
    }
  }

  applyFilter() {
    if (this.filterForm.valid) {
      const employeeName = this.filterForm.get('fEmployeeName').value;
      this.filter = new TrainingCertificateDetailsItemFilter({
        id_curso: this.certificateId,
        top_n: this.recPP,
        page: this.pageNum,
        order_by: this.orderBy,
        trabajador: employeeName
      });
      this.getTrainingCertificateItemDetails();
    } else {
      this.error = 'Valores de filtro no válidos';
      this._commonUiService.showWarningMessage(this.error);
    }
  }

  getTrainingCertificateItemDetails(): void {
    this.error = '';    // erase previous errors
    this.isLoading = true;
    this._tariningCertificateDetailsService.getTrainingCertificateDetails(this.filter).subscribe(response => {
      this.responseList = response;
      if (this.responseList != null) {
        if (this.responseList.webError != null) {
          this.error = this.responseList.webError.messageToUser;
          this._commonUiService.showWarningMessage(this.error);
        } else {
          this.trainingCertificateDetails = response.data;
          this.trainingDescriptionArray = this.trainingCertificateDetails.curso_descripcion.split(/\r?\n/);
          this.participantsList = response.data.participantes;
          this.recCount = response.links.rowcount;
          this.pageCnt = response.links.rowcount === 0 || !response.links.rowcount ? 1 : Math.ceil(response.links.rowcount / this.recPP);
          this.paginationConfig.totalItems = this.recCount;
        }
      }
      this.isLoading = false;
    }, error => {
      this.error = Utils.formatError(error);
      this._commonUiService.showWarningMessage(this.error);
      this.isLoading = false;
    });
  }

  onChangeOrder(orderByElement: DocumentoOrderQuery) {
    const direction = orderByElement.direction === 'ASC' ? 'DESC' : 'ASC';
    this.queryTypes.forEach((e, i) => {
      e.direction = '';
    });
    this.queryTypes.filter(e => e.id === orderByElement.id)[0].direction = direction;
    this.orderBy = direction === 'ASC' ? orderByElement.id : orderByElement.id + ' DESC';
    this.applyFilter();
  }

  btnFilter() {
    this.pageNum = 1;
    this.paginationConfig.currentPage = this.pageNum;
    this.applyFilter();
  }

  downloadEmployeeCertificate(docId: string): void {
    this.isLoading = true;
    const docFile: DocumentoFileQuery = { id_documento: docId };
    this._trainingCertificateFileService.getEmployeeCertificate(docFile).subscribe(response => {
      // Need to check the download process once the client repond
      this.downloadFile(response);
      this.isLoading = false;
    }, error => {
      this.error = Utils.formatError(error);
      this._commonUiService.showWarningMessage(this.error);
      this.isLoading = false;
    });
  }

  downloadCompanyCertificate(docId: string): void {
    this.isLoading = true;
    const docFile: DocumentoFileQuery = { id_documento: docId };
    this._trainingCertificateFileService.getCompanyCertificate(docFile).subscribe(response => {
      // Need to check the download process once the client repond
      this.downloadFile(response);
      this.isLoading = false;
    }, error => {
      this.error = Utils.formatError(error);
      this._commonUiService.showWarningMessage(this.error);
      this.isLoading = false;
    });
  }

  private downloadFile(response: DocumentoFileResponse): void {
    this.responseFile = response;
    if (this.responseFile != null) {
      if (this.responseFile.webError != null) {
        this.error = this.responseFile.webError.messageToUser;
        this._commonUiService.showWarningMessage(this.error);
      }
      else {
        const linkSource = 'data:application/pdf;base64,' + this.responseFile.data.fichero + '\n';
        const downloadLink = document.createElement('a');
        const fileName = this.responseFile.data.nombre;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }
    }
  }

  pageChanged(pageNumber: number): void {
    if (pageNumber > 0 && pageNumber <= this.pageCnt && this.pageNum !== pageNumber) {
      this.paginationConfig.currentPage = pageNumber;
      this.pageNum = pageNumber;
      this.applyFilter();
    }
  }

}
