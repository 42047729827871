import { PowerBiReportsModule } from './modules/power-bi-reports/power-bi-reports.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './layout/main-layout/header/header.component';
import { MenuComponent } from './layout/main-layout/menu/menu.component';
import { FooterComponent } from './layout/main-layout/footer/footer.component';
import { MainLayoutComponent } from './layout/main-layout/main-layout.component';
import { LoginLayoutComponent } from './layout/login-layout/login-layout.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DndModule } from 'ngx-drag-drop';
import { LeftPanelComponent } from './layout/main-layout/left-panel/left-panel.component';
import { CoreModule } from './core/core.module';
import { PageIndexModule } from './modules/page-index/page-index.module';
import { HealthReportsModule } from './modules/health-reports/health-reports.module';
import { CompDocModule } from './modules/comp-doc/comp-doc.module';
import { TrainingModule } from './modules/training/training.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TitleService } from './shared/services/title.service';
import { OwlDateTimeSpanish } from './model/owl-date-time-spanish';
import { OwlDateTimeIntl, OwlDateTimeModule, OwlMomentDateTimeModule, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from '@busacca/ng-pick-datetime';
import { ProceduresModule } from './modules/procedures/procedures.module';
import { RouterModule } from '@angular/router';
import { TrabajadoreModule } from './modules/trabajadore/trabajadore.module';
import { CommonCalenderComponent } from './shared/components/common-calender/common-calender.component';
import { CommonMessageComponent } from './shared/components/common-message/common-message.component';
import { CalenderComponent } from './modules/auto-reversa/calender/calender.component';
import { SharedModule } from './shared/shared.module';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export const MY_MOMENT_FORMATS = {
  parseInput: 'D/M/YYYY',
  fullPickerInput: 'D/M/YYYY',
  datePickerInput: 'D/M/YYYY',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'D/M/YYYY',
  monthYearA11yLabel: 'MMMM YYYY',
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    MenuComponent,
    FooterComponent,
    CalenderComponent,
    MainLayoutComponent,
    LoginLayoutComponent,
    LeftPanelComponent,
    CommonCalenderComponent,
    CommonMessageComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    CoreModule,
    PageIndexModule,
    HealthReportsModule,
    CompDocModule,
    TrainingModule,
    PowerBiReportsModule,
    NgSelectModule,
    ReactiveFormsModule,
    FormsModule,
    OwlDateTimeModule,
    OwlMomentDateTimeModule,
    ProceduresModule,
    SharedModule,
    TrabajadoreModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    ToastrModule.forRoot({
      timeOut: 2000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true
    }),
    DndModule,
    RouterModule
  ],
  providers: [
    TitleService,
    { provide: OWL_DATE_TIME_LOCALE, useValue: 'es' },
    { provide: OwlDateTimeIntl, useClass: OwlDateTimeSpanish },
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
