export class LanguageRequest {
    
    incl: string;
    page_number: number;
    page_size: number;
    type: string;
    relatedId: number;

    constructor() {
        this.page_number = 1;
        this.page_size = 10;
        this.relatedId = 0;
    }
}
