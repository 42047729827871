<app-spinner *ngIf="isLoading"></app-spinner>
<div class="agendas-breadcrumb">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item" aria-current="page">Analitica BI</li>
    </ol>
  </nav>
</div>
<div style="height: 100vh !important">
  <powerbi-report *ngIf="embedConfigObs | async as embedConfig; else noReport" [embedConfig]="embedConfig"  [cssClassName]="reportClass" ></powerbi-report>

          <!-- This block will show when no report is found -->
          <ng-template #noReport>
            <p class="active-reports">{{errorMessage}}</p>
          </ng-template>
       
</div>

