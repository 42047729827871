import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DocumentoListFilter } from '../model/documento-list-filter';
import { Observable } from 'rxjs';
import { DocumentoListResponse } from '../model/documento-list-response';
import { Constants } from './constants';
import { DocumentoOrderQuery } from '../model/documento-order-query';

@Injectable({ providedIn: 'root' })
export class DocumentosListService {

  url = "empresa/documentos";

  public order_query_list: DocumentoOrderQuery[];

  constructor(private _httpClient: HttpClient) {
    this.order_query_list = [
      { id: 'NOMBRE', description: 'Nombre' },
      { id: 'APELLIDOS', description: 'Apellidos' },
      { id: 'CENTRO', description: 'Centro' },
      { id: 'FECHA_RECONOCIMIENTO', description: 'Fecha visita' },
      { id: 'FECHA_DOCUMENTO', description: 'Fecha documento' },
      { id: 'INEXISTENTE', description: 'incorrect order by' },
    ];
  }

  public getDocumentosList(_filter: DocumentoListFilter): Observable<DocumentoListResponse> {
    return this._httpClient.post<DocumentoListResponse>(
      Constants.apiRoot + this.url, _filter);
  }

}
