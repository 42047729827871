<div class="row header-title">
    <div class="col-md-8 col-lg-10 title-line s-w-70">
        <h5>Trabajadores/as</h5>
    </div>
    <div class="col-md-4 col-lg-2 s-w-30">
        <a [routerLink]="['../pageindex']"> Volver</a>
    </div>
</div>
<nav class="add-exception-tabs">
    <ul class="nav nav-tabs" role="tablist">
        <li class="nav-item">
            <a class="nav-link active" id="nav-activos-tab" data-toggle="tab" href="#nav-activos" role="tab" 
                aria-controls="nav-agregar" aria-selected="true" (click)="getTrabajadore(true)"> Trabajadores Activos
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link ml-2" id="nav-inactivos-tab" data-toggle="tab" href="#nav-inactivos" role="tab"
                aria-controls="nav-copiar" aria-selected="false" (click)="getTrabajadore(false)"> Trabajadores Inactivos
            </a>
        </li>
    </ul>
</nav>

<div class="card card-tabs">
    <div id="nav-tabContent" class="tab-content">
        <div id="nav-activos" class="tab-pane p-3 fade show active" role="tabpanel" aria-labelledby="nav-activos-tab">
            <div class="form-group">
                <app-trabajadore-active [activeEvent]="eventsSubject.asObservable()"></app-trabajadore-active>
            </div>            
        </div>

        <div id="nav-inactivos" class="tab-pane p-3 fade show" role="tabpanel" aria-labelledby="nav-inactivos-tab">
            <div class="form-group">
                <app-trabajadore-inactive [inactiveEvent]="eventsSubject.asObservable()"></app-trabajadore-inactive>
            </div>            
        </div>
    </div>
</div>