import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TrabajadoresRequest } from '../model/trabajadores-request';
import { TrabajadoresRespond } from '../model/trabajadores-respond';
import { Observable } from 'rxjs';
import { Constants } from './constants';
import { TrabajadoresDisableRequest } from '../model/trabajadores-disable-request';
import { TrabajadoresDisableRespond } from '../model/trabajadores-disable-respond';
import { TrabCertificatesRequest } from '../model/TrabCertificatesRequest';
import { TrabCertificatesRespond } from '../model/TrabCertificatesRespond';
import { PreventorRequest } from '../model/PreventorRequest';
import { PreventorResponse } from '../model/PreventorResponse';

@Injectable({
  providedIn: 'root'
})
export class TrabajadoresService {

  url = "empresa/trabajadores";

  constructor(private _httpClient: HttpClient){    
  }

  public getTrabajadores(filter: TrabajadoresRequest): Observable<TrabajadoresRespond> {
    return this._httpClient.post<TrabajadoresRespond>(Constants.apiRoot + this.url , filter);
  }

  public disableTrabajadores(filter: TrabajadoresDisableRequest): Observable<TrabajadoresDisableRespond> {
    return this._httpClient.post<TrabajadoresDisableRespond>(Constants.apiRoot + this.url + '/disable' , filter);
  }

  public enableTrabajadores(filter: TrabajadoresDisableRequest): Observable<TrabajadoresDisableRespond> {
    return this._httpClient.post<TrabajadoresDisableRespond>(Constants.apiRoot + this.url + '/enable' , filter);
  }

  public getCertificates(filter: TrabCertificatesRequest): Observable<TrabCertificatesRespond> {
    return this._httpClient.post<TrabCertificatesRespond>(Constants.apiRoot + this.url + '/certificados' , filter);
  }

  public getAllPreventor(filter: PreventorRequest): Observable<PreventorResponse[]> {
    return this._httpClient.post<PreventorResponse[]>(Constants.powerBIRoot + 'Preventor/allPreve' , filter);
  }

  public saveRenunciaDate(dataModel: any){
    return this._httpClient.post<any[]>(Constants.apiRoot + this.url + '/renuncia', dataModel);
  }
}
