import { Component, OnInit } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';
import { SharedDataService } from 'src/app/shared/services/shared-data.service';
import { IReportEmbedConfiguration, models } from 'powerbi-client';
import { PowerBiService } from 'src/app/core/power-bi.service';


@Component({
  selector: 'app-page-index',
  templateUrl: './page-index.component.html',
  styleUrls: ['./page-index.component.scss']
})
export class PageIndexComponent implements OnInit {
  reportConfig: IReportEmbedConfiguration = {
    type: 'report',
    embedUrl: undefined,
    tokenType: models.TokenType.Embed,
    accessToken: undefined,
    settings: undefined,
  };

  reportClass = 'report-container hidden reportClass';
  reportObject: any;
  responseList = null;
  error = '';
  userID: string;
  isCompanySelected: boolean = false;
  company: any;
  isReportShow: boolean = false;
  public isLoading = false;
  roleText: any = '';
  errorMessage: string;

  constructor(private jwtHelper: JwtHelperService,
    private _powerBiService: PowerBiService,
    private _sharedDataService: SharedDataService,
    private router: Router) {      
    }

  ngOnInit(): void {
    this.getUserId();
    this._sharedDataService.currentCentros.subscribe(respondData => {
      if (respondData != null) {
        this.getPageAttributes(respondData);
        this.loadPowerBIReport();
      } else {
        this.resetPowerBIConfig();
        this.isCompanySelected = false;
      }
    });
  }

  getPageAttributes(respondData: any = null): void {
    if (respondData == null) {
      var retrievedObject = localStorage.getItem('centrosSource');
      this.responseList = JSON.parse(retrievedObject);
    }
    else {
      this.responseList = respondData;
    }

    if (this.responseList.data?.attributes != null) {
      this.isCompanySelected = true;
    } else {
      this.isCompanySelected = false;
    }
  }


  resetPowerBIConfig() {
    this.isReportShow = false;
  } 

  loadPowerBIReport() {
    if (localStorage.getItem('company') != null) {      
      this.company = JSON.parse(localStorage.getItem('company'));
      this.isLoading = true;
  
      this._powerBiService.getPowerBIData(this.company.empresa_id, 0).subscribe({
        next: (response) => {
          if (response) {
            this.reportObject = response;
            this.reportConfig = {
              ...this.reportConfig,
              id: this.reportObject.EmbedReport[0].ReportId,
              embedUrl: this.reportObject.EmbedReport[0].EmbedUrl,
              accessToken: this.reportObject.EmbedToken.Token,
            };
            this.isReportShow = true;
          } else {
            this.isReportShow = false;
          }
          this.isLoading = false;
        },
        error: (error) => {
          this.isLoading = false;
          this.isReportShow = false;
          this.errorMessage = 'Report Not Available';
        }
      });
    }
  }
  

  private getUserId(): void {
    const logedUser = JSON.parse(localStorage.getItem("loggeduser"));
    const token = logedUser.gesemeApiToken;
    const tokenDecodedObject = JSON.parse(JSON.stringify(this.jwtHelper.decodeToken(token)))

    this.userID = tokenDecodedObject.sub;
  }

  //navigate to page
  navigate(url) {
    this.router.navigateByUrl(url);
  }

  // navigate to manual pdf 
  navigateToManual() {
    let role = JSON.parse(localStorage.getItem('role'));
    let roleText = role.rol_txt;
    let url: string = '';
    // check role to navigate to the pdf
    if (roleText === 'EMPRESA' || roleText === 'EMPRESA_AGRUPADA') {
      url = 'https://portal.geseme.com/_Content/geseme/Reports/manuales/Manual%20Area%20Empresa%20Cliente.pdf';
    } else if (roleText === 'ADMIN' || roleText === 'TECNICO') {
      url = 'https://portal.geseme.com/_Content/geseme/Reports/manuales/Manual%20Area%20Empresa%20Interno.pdf';
    }

    if (url) {
      window.open(url, '_blank');
    }
  }

  powerBiRedirect(url: string ){
      window.open(url , '_blank');
  }
}
