import { Injectable } from '@angular/core';
import { SelectOptions } from '../model/select-options.model';

@Injectable({
  providedIn: 'root'
})
export class DropdownService {

  WrappedToDropDownList(res: Array<any>, displayMember: string, valueMemeber: string, isEmployee : boolean = false,
    withAny?: boolean, isEstado : boolean = false,): Array<SelectOptions> {
    const tempList = [];
    if (withAny) {
      tempList.push({ label: 'Cualquiera', value: String(0) });
    }

    if(isEstado)
    {
      res.forEach(element => {
          const optItem = { label:element[displayMember],
            value:  element[valueMemeber],
            power2_bit :element['power2_bit']
          };
          tempList.push(optItem);
      });
    }
    else if(isEmployee)
    {
      res.forEach(element => {
          let  label = element['apellidos'];
          label = (element[displayMember] != null && element[displayMember].length > 0) ? label +', '+ element[displayMember]: label; 
          label = (element['nip'] != null && element['nip'].length > 0) ? label +' - '+ element['nip']: label; 
          label = (element['empresa_sede_txt'] != null && element['empresa_sede_txt'].length > 0 ) ? label +' - ' + element['empresa_sede_txt']: label; 
          label = (element['tel1']!= null && element['tel1'].length > 0) ? label  + ' - ' + element['tel1']: label;
          const valueType = typeof(element[valueMemeber]) =="number" ?  Number : String;
          const optItem = { label:label,
           value:  valueType(element[valueMemeber]) , companyId: element['empresa_id'], companyText: element['empresa_txt']  };
        tempList.push(optItem);
      });
    }
    else
    {
    res.forEach(element => {
      const valueType = typeof(element[valueMemeber]) =="number" ?  Number : String;
      const optItem = { label: element[displayMember], value: valueType(element[valueMemeber]) };
      tempList.push(optItem);
    });
  }
    return tempList;
  }

}
