import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DocCentTrabResponse } from '../model/doccenttrab-response';
import { Constants } from './constants';
import { LanguageRespond } from '../model/language-respond';
import { TrabajadorAllowRequest } from '../model/trabajador-allow-request';

@Injectable({
  providedIn: 'root'
})
export class CentrosytrabajadoresListService {

  url = "empresa";
  company: any;

  constructor(private _httpClient: HttpClient) {
  }

  public getCentrosytrabajadoresList(companyData: any): Observable<DocCentTrabResponse> { 
    return this._httpClient.post<DocCentTrabResponse>(Constants.apiRoot + this.url + "/centrosytrabajadores" , companyData);
  }

  public getWorkCenterList(filter: any): Observable<any> {
    return this._httpClient.post<LanguageRespond>(Constants.apiRoot + this.url + '/centros' , filter);
  }

  public getTrabajadoresAllowed(filter: TrabajadorAllowRequest): Observable<any> {
    return this._httpClient.post<any>(Constants.apiRoot + this.url + '/trabajadores-allowed' , filter);
  }

  public getDocumentType(filter: any): Observable<any> {
    return this._httpClient.post<any>(Constants.apiRoot + this.url + '/tipos-archivo' , filter);
  }

}
