import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './core/auth-service.component';
import { UserContext } from './model/user-context';
import { SimpleClaim } from './model/simple-claims';
import { Constants } from './constants';
import { TitleService } from './shared/services/title.service';
import { CentrosytrabajadoresListService } from './core/centrosytrabajadores-list.service';
import { DocCentTrabResponse } from './model/doccenttrab-response';
import { Utils } from './core/utils';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Area Empresa';

 constructor(translate: TranslateService, private _authService: AuthService) {
    translate.setDefaultLang('en');
    // TODO get language list from API
    translate.addLangs(['en', 'es']);
  }

  ngOnInit() {
    
  }

 
}
