<div class="company-node" *ngFor="let child of treeViewModel; let i = index;">
    <div class="node" [ngClass]="{'node-collapse' : child.children && child.children.length > 0}">       
        <div class="custom-control custom-radio node-radio">
            <input class="custom-control-input mt-2" type="radio" mdbInput 
                name="checktree" [checked]="child.id_branch == 'EMPRESA' ? true : false"
                [id]="i + '_' + child.id_branch + '_' + child.id + '_' + child.random_id" 
                [value]="i + '_' + child.id_branch + '_' + child.id + '_' + child.random_id"
                (change)="setSelectedBranchId(child.id_branch, child.id)">
            <label class="custom-control-label m-t-1" [for]="i + '_' + child.id_branch + '_' + child.id + '_' + child.random_id">
                {{child.name}}
            </label>
        </div>
        <button type="button" class="node-btn" *ngIf="child.children && child.children.length > 0"
            (click)="treeCollapse.toggle()" [attr.aria-expanded]="!child.isCollapsed" aria-controls="collapseExample">
            <span *ngIf="child.isCollapsed" class="fa fa-caret-right arrow-tree"></span>
            <span *ngIf="!child.isCollapsed" class="fa fa-caret-down arrow-tree"></span>
        </button>
    </div>
    <div #treeCollapse="ngbCollapse" [(ngbCollapse)]="child.isCollapsed">
        <app-company-tree [treeViewModel]="child.children" (branchSelected)="setSelectedInnerBranchId($event)"></app-company-tree>
    </div>
</div>