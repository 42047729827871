import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DocumentoFileQuery } from '../model/documento-file-query';
import { DocumentoFileResponse } from '../model/documento-file-response';
import { Constants } from './constants';

@Injectable({
  providedIn: 'root'
})
export class CompanyInformationDocumentFileService {

  private url = 'empresa/tree/documento';

  constructor(
    private _httpClient: HttpClient) {
  }

  public getCompanyInformationDocument(documento: DocumentoFileQuery): Observable<DocumentoFileResponse> {
    return this._httpClient.post<DocumentoFileResponse>(Constants.apiRoot + this.url, documento);
  }
}
