<div class="modal-body">
  <div class="row pl-4">
    <div class="col-md-10 text-left pl-0">
      <h6 class="font-weight-bold mb-4">Para completar la cita Indicanose:</h6>
    </div>
    <div class="col-md-2">
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">
        <span aria-hidden="true">×</span>
      </button>
    </div>
  </div>

  <form [formGroup]="appointmentForm" (ngSubmit)="confirmAppoinment()">
    <div class="row mt-4">
      <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12">
        <div class="form-group">
          <label class="mb-0">Puesto de trabajo:</label>
          <ng-select #selectAgenda (close)="selectAgenda.blur()" loadingText="Cargando..."
            notFoundText="No hay resultados" [items]="puestoList" id="puestovs_id" name="puestovs_id" [clearable]="true"
            [virtualScroll]="true" bindLabel="puestovs_nombre" bindValue="id" placeholder="Seleccione"
            formControlName="puestovs_id" required></ng-select>

          <span *ngIf="(appointmentForm.controls.puestovs_id.dirty || appointmentForm.controls.puestovs_id.touched ||
                              this.submitted) && appointmentForm.controls.puestovs_id.invalid"
            class="is-invalid red-clr"> requerida
          </span>
        </div>
      </div>
    </div>
    <div class="row mt-5 mb-3">
      <div class="col-lg-8 col-xl-8 col-md-8 col-sm-12"></div>
      <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12">
        <button class="btn btn-primary w-100" type="submit">Finalizar</button>
      </div>
    </div>
  </form>
</div>