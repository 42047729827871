/*
  object sample:
  {
    "centro": "20170429181513-5835075-0561-W",
    "trabajador": null,
    "fecha_reconocimiento_desde": "20190101",
    "fecha_reconocimiento_hasta": "20201231",
    "fecha_documento_desde": "",
    "fecha_documento_hasta": "",
    "order_by": "APELLIDOS",
    "top_n": 10,
    "page": 1
  }
*/
export class DocumentoListFilter {
  empresa_id?: string;
  centro: string;
  trabajador: string;  
  fecha_reconocimiento_desde: string;
  fecha_reconocimiento_hasta: string;
  fecha_documento_desde: string;
  fecha_documento_hasta: string;
  order_by: string;
  top_n: number;
  page: number;

  public constructor(init?: Partial<DocumentoListFilter>) {
    Object.assign(this, init);
  }
}
