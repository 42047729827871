import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DocumentoOrderQuery } from '../model/documento-order-query';
import { TrainingCertificateListFilter } from '../model/training-certificate-list-filter';
import { TrainingCertificateListResponse } from '../model/training-certificate-list-response';
import { Constants } from './constants';
import { TrabajadoresDiplomasFilter } from '../model/trabajadores-diplomas-filter';
import { TrabajadoresDiplomasItem } from '../model/Trabajadores-Diplomas-Item';
import { TrabajadoresDiplomasResponse } from '../model/trabajadores-diplomas-response';

@Injectable({
  providedIn: 'root'
})
export class TrainingCertificateListService {

  private url = 'empresa';
  public order_query_list_Certi: DocumentoOrderQuery[];
  public order_query_list_Diplom: DocumentoOrderQuery[];

  constructor(private _httpClient: HttpClient) {
    this.order_query_list_Certi = [
      { id: 'NAME', description: 'Nombre' },
      { id: 'INITIAL_DATE', description: 'Fecha Incio' },
      { id: 'END_DATE', description: 'Fecha Fin' },
      { id: 'DURATION', description: 'Duracion' },
      { id: 'COUNT', description: 'Num. Trabajadores' },
    ];
    this.order_query_list_Diplom = [
      { id: 'NIF', description: 'NIF' },
      { id: 'NAME', description: 'Nombre trabajadores' },
    ]
  }

  public getTrainingCertificateList(filter: TrainingCertificateListFilter): Observable<TrainingCertificateListResponse> {
    return this._httpClient.post<TrainingCertificateListResponse>(Constants.apiRoot + this.url + '/formaciones', filter);
  }

  public getTrabajadoreDiplomadoList(filter: TrabajadoresDiplomasFilter): Observable<TrabajadoresDiplomasResponse> {
    return this._httpClient.post<TrabajadoresDiplomasResponse>(Constants.apiRoot + this.url + '/trabajadores-diplomados', filter);
  }
}
