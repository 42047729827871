import { EventEmitter, Injectable, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class CommonUiService {

  @Output() toggleMenu: EventEmitter<void> = new EventEmitter();

  constructor(
    private toaster: ToastrService,
    private translateService: TranslateService,
    private datePipe: DatePipe) { }

  showToastByErrorCode(code, jsonPath) {
    code = code.split(':');

    this.translateService.get(jsonPath + '.' + [code[1]]).subscribe((message) => {
      switch (code[0].toLowerCase()) {
        case 'i':
          this.toaster.success(message, this.translateService.instant('common.success'));
          break;
        case 'w':
          this.showWarningToast(this.translateService.instant('common.warning'), message);
          break;
        case 'e':
          this.showFailToast(this.translateService.instant('common.error'), message);
          break;
      }
    });
  }

  showWarningToast(title, message) {
    this.toaster.warning(message, title);
  }

  showSuccessToast(key) {
    const title = this.translateService.instant('common.success');
    const message = this.translateService.instant(key);
    this.toaster.success(message, title);
  }

  showWarningToastInstant(key) {
    const title = this.translateService.instant('common.warning');
    const message = this.translateService.instant(key);
    this.toaster.warning(message, title);
  }

  showWarningMessage(message) {
    const title = this.translateService.instant('common.warning');
    this.toaster.warning(message, title, { timeOut: 7000 });
  }

  showFailToast(title, message) {
    this.toaster.error(message, title, { timeOut: 5000 });
  }

  showConfirm(message?) {
    return new Promise((resolve) => {
      Swal.fire({
        title: '',
        text: message ? message : this.translateService.instant('common.deleteMessage'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.translateService.instant('common.yes'),
        cancelButtonText: this.translateService.instant('common.no')
      }).then((result) => {
        if (result.value) {
          resolve(true);
          return;
        }
        resolve(false);
      });
    });
  }

  showConfirmWithoutIcon(title?, message?) {
    return new Promise((resolve) => {
      Swal.fire({
        title: title,
        html: message ? message : this.translateService.instant('common.deleteMessage'),
        showCancelButton: true,
        cancelButtonText: this.translateService.instant('Cancelar'),
        confirmButtonText: this.translateService.instant('Confirmar'),
        reverseButtons: true, // This ensures that the Cancel button appears first
      }).then((result) => {
        if (result.value) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  }
  

  dateToSpanish(dateToFormat: string): string {
    return this.datePipe.transform(dateToFormat, 'dd/MM/yyyy');
  }

  dateToString(dateToFormat: Date): string {
    return this.datePipe.transform(dateToFormat, 'yyyyMMdd');
  }

  financialdateToString(newDate: string) {
    let day = newDate.substring(0, 2);
    let month = newDate.substring(3, 5);
    let year = newDate.substring(6, 10);
    
    return year+month+day;
  }

  dateStringFormat(dateToFormat: string): string {
    let formattedDate = '';
    if (dateToFormat) {
      formattedDate = dateToFormat.substring(6, 8) + '/' + dateToFormat.substring(4, 6) + '/' + dateToFormat.substring(0, 4);
    }
    return formattedDate;
  }
}
