import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AutoReversaService } from 'src/app/core/auto-reversa.service';
import { CommonMessageComponent } from 'src/app/shared/components/common-message/common-message.component';
import { TrabajadoreActiveComponent } from '../../trabajadore/trabajadore-active/trabajadore-active.component';
import { SharedDataService } from 'src/app/shared/services/shared-data.service';

@Component({
  selector: 'app-appoinment-confirmation',
  templateUrl: './appoinment-confirmation.component.html',
  styleUrls: ['./appoinment-confirmation.component.scss']
})
export class AppoinmentConfirmationComponent implements OnInit {
  submitted: boolean = false;
  puestoList: any[] = [];  
  puestoRequestData = {};
  @ViewChild("component1") component1: TrabajadoreActiveComponent;

  appointmentForm = new FormGroup({
    puestovs_id: new FormControl(null,[Validators.required]),
  });
  constructor(private router: Router, 
    private modalService: NgbModal,
    public modal: NgbActiveModal,
    private sharedService: SharedDataService,
    private autoreversaService : AutoReversaService) { }

  ngOnInit(): void {
    this.getPuestoList();
  }

  confirmAppoinment() {
    this.submitted = true;    
    if(this.appointmentForm.valid){      
      this.autoreversaService.addAppointment(this.prepareFormData()).subscribe(res=>{
        if(res?.webError==null && res?.data?.messageToUser!=null){
          let regx  =  /(202[0-9]en)/;
          let messageToUser = res.data.messageToUser || ' '; 
          let regxResult :any= regx.exec(messageToUser) || '';
          this.modal.dismiss();
          messageToUser = messageToUser.slice(0, regxResult['index']+4) + " " + messageToUser.slice(regxResult['index']+4 + Math.abs(0));
          this.navigateUserMessage(messageToUser);
          this.sharedService.sendTrabajadoreClick();
        } 
      })
    }
  }

  prepareFormData(): any {
    let formData = this.appointmentForm.value;
    const userDetails = JSON.parse(localStorage.getItem("employeeDetails") || 'null');
    let returnData = null;
    returnData = {
      "attribs": {
        "fecha": userDetails.fecha,
        "hora": userDetails.hora.split(':').join('').toString().padEnd(6, '0'),
        "agenda_id": userDetails.agenda_id,
        "centro_id": userDetails.centro_id,
        "company_id": userDetails.company_id,
        "telefono": userDetails.telef,
        "email": userDetails.email,
        "puestovs_id": formData.puestovs_id
      },
      "persona": {
        "dni": userDetails.dni,
        "fecha_nacimiento": userDetails.fecha_nacimiento
      }
    };
    return returnData;
  }

  getPuestoList() {
    const userDetails = JSON.parse(localStorage.getItem("employeeDetails") || 'null');
    this.puestoRequestData = { 
      "type": "FILTER",
      "dni":userDetails.dni,
      "fecha_nacimiento":userDetails.fecha_nacimiento,
      "persona_id":userDetails.persona_id, 
      "company_id":userDetails.company_id 
    };

    return this.autoreversaService.getPuestosvs(this.puestoRequestData).subscribe(res=> { 
      if(res && res.data) {
        this.puestoList = res.data;
        /// set default value if exists 
        const defaultValue = this.puestoList.filter(x => x.puestovs_default == true);
        if (defaultValue.length > 0) {
          this.setDefaultValue(defaultValue);
        }
      }
      return res.data;
    });
  }

  setDefaultValue(defaultValue: any) { 
    setTimeout(() => {
        this.appointmentForm.get('puestovs_id').setValue(defaultValue[0].id);
    }, 1000);  
  }

  navigateUserMessage(messageToUser: any) {
    const modalRef = this.modalService.open(CommonMessageComponent, { centered: true });
    modalRef.componentInstance.message = btoa(messageToUser);
    modalRef.componentInstance.fromUrl = btoa(this.router.url);
  }

}



