export class AdvanceFilterRequest {
    id_branch: string;
    after_id: string;
    nameLike: string;
    dateFrom: string;
    dateTo: string;
    order_by: string;
    docs_validos: boolean = false;
    centro_id: string;
    trabajador_id: string;
    tipo_doc_id: string;
    top_n: number;
    page: number;
  
}
