<div class="modal-body">
  <div class="row">
    <div class="col-md-10 text-left pl-0">
    </div>
    <div class="col-md-2">
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">
        <span aria-hidden="true">×</span>
      </button>
    </div>
  </div>
  <div class="row text-center mt-2 mb-2 ml-2 mr-2">
    <h6 class="font-weight-bold">
      {{ message }}
    </h6>
  </div>
</div>