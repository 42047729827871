import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../core/auth-service.component';
import { UserContext } from '../../../model/user-context';
import { SimpleClaim } from '../../../model/simple-claims';
import { Constants } from '../../../constants';

@Component({
  selector: 'app-home',
  templateUrl: 'home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {

  isLoggedIn = false;
  currentUserContext: UserContext = null;

  constructor(private _authService: AuthService) { }

  ngOnInit() { }


  login() {
    this._authService.login();
    return false;
  }

  isAdmin() {
    return this.currentUserContext.auth && this.currentUserContext.auth.isAdmin;
  }

  userNameInfo() {
    let textualInformation: String = "";
    if (!!this.currentUserContext && !!this.currentUserContext.auth) {
      let theClaim: SimpleClaim;
      theClaim = this.currentUserContext.auth.claims.find(x => x.type == "sub");
      if (!!theClaim)
        textualInformation = theClaim.value.toString();
    }
    return textualInformation
  }


  levelAccessInfo() {

    let textualInformation: String = "";

    if (!!this.currentUserContext && !!this.currentUserContext.auth) {
      textualInformation = this.currentUserContext.auth.userPermissions[0].accessLevelId.toString();
      let i = this._authService.getAuthPermissions().accessLevelId;
      switch (this._authService.getAuthPermissions().accessLevelId) {
        case Constants.AUTH_NO_ACCESS: {
          textualInformation = "all apps->no access";
          break;
        }
        case Constants.AUTH_ADMIN: {
          textualInformation = "all apps->admin access";
          break;
        }
        case Constants.AUTH_AREAENTERPR_ADMIN: {
          textualInformation = "areaenterpr->admin";
          break;
        }
        case Constants.AUTH_AREAENTERPR_NO_ACCESS: {
          textualInformation = "areaenterpr->no access";
          break;
        }
        case Constants.AUTH_AREAENTERPR_FULL_EDIT: {
          textualInformation = "areaenterpr->full access all enterprises (query all)";
          break;
        }
        case Constants.AUTH_AREAENTERPR_ONLY_MINE: {
          textualInformation = "areaenterpr->only docs from my enterprise";
          break;
        }
        default: {
          break;
        }
      }
    }
    return textualInformation;
  }
}
