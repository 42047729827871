import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { HealthReportsComponent } from './health-reports/health-reports.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { OwlDateTimeModule, OwlMomentDateTimeModule } from '@busacca/ng-pick-datetime';
import { SharedModule } from 'src/app/shared/shared.module';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [HealthReportsComponent],
  imports: [
    CommonModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    OwlDateTimeModule,
    OwlMomentDateTimeModule,
    NgxPaginationModule,
    SharedModule,
    RouterModule
  ],
  providers: [DatePipe]
})
export class HealthReportsModule { }
