import { PowerBIEmbedModule } from 'powerbi-client-angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageIndexComponent } from './page-index/page-index.component';
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';
import { SharedModule } from 'src/app/shared/shared.module';
@NgModule({
  declarations: [PageIndexComponent],
  imports: [
    CommonModule,
    PowerBIEmbedModule,
    SharedModule
  ],
  providers: [{ provide: JWT_OPTIONS, useValue: JWT_OPTIONS }, JwtHelperService],
})

export class PageIndexModule { }
