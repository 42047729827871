import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ApiService {

  constructor(private httpClient: HttpClient) { }

  get(url: string, httpParams?) {
    let params = new HttpParams();

    if (httpParams) {
      for (let key in httpParams) {
        if (httpParams.hasOwnProperty(key)) {
          params = params.append(key, httpParams[key]);
        }
      }
    }

    return this.httpClient.get<any>(url, { params })
      .pipe(map(response => response), catchError(err => throwError(err.error ? err.error : err)));
  }

  post(url: string, data: any, httpParams?) {
    let params = new HttpParams();

    if (httpParams) {
      for (let key in httpParams) {
        if (httpParams.hasOwnProperty(key)) {
          params = params.append(key, httpParams[key]);
        }
      }
    }

    return this.httpClient.post<any>(url, data, { params })
      .pipe(map(response => response), catchError(err => throwError(err.error ? err.error : err)));
  }

  put(url: string, data: any, httpParams?) {
    let params = new HttpParams();

    if (httpParams) {
      for (let key in httpParams) {
        if (httpParams.hasOwnProperty(key)) {
          params = params.append(key, httpParams[key]);
        }
      }
    }
    return this.httpClient.put<any>(url, data, { params })
      .pipe(map(response => response), catchError(err => throwError(err.error ? err.error : err)));
  }

  delete(url: string, httpParams?) {
    let params = new HttpParams();

    if (httpParams) {
      for (let key in httpParams) {
        if (httpParams.hasOwnProperty(key)) {
          params = params.append(key, httpParams[key]);
        }
      }
    }
    return this.httpClient.delete<any>(url, { params })
      .pipe(map(response => response), catchError(err => throwError(err.error ? err.error : err)));
  }

  postFormData(url: string, form) {
    let formData = new FormData();

    if (form) {
      for (let key in form) {
        if (form.hasOwnProperty(key)) {
          formData.append(key, form[key]);
        }
      }
    }
    return this.httpClient.post<any>(url, formData)
      .pipe(map(response => response), catchError(err => throwError(err.error ? err.error : err)));
  }
}
