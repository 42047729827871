import { Component, OnInit } from '@angular/core';
import { TitleService } from '../../../shared/services/title.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  footer : string;

  constructor( private footerService: TitleService) { }

  ngOnInit(): void {
    this.footerService.footer.subscribe(ft => {
      this.footer = ft;
    });
  }

}
