<div class="modal-body">
  <div class="row pl-4">
    <div class="col-md-10 text-left pl-0">
      <h5 class="font-weight-bold mb-4">Elige fecha para la visita :</h5>
    </div>
    <div class="col-md-2">
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">
        <span aria-hidden="true">×</span>
      </button>
    </div>
  </div>

  <div class="row mx-0 ml-2">
    <app-common-calender (selected)="setSelected($event)"></app-common-calender>
  </div>
  <div class="mt-3 time-slots-div">
    <div class="row day-title">
      <h6 class="font-weight-bold">
        {{
        selectedDate?.toLocaleDateString("es-ES", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric"
        })
        }}
      </h6>
    </div>
    <div id="scroll-y" class="row time-labels">
      <h6 class="font-weight-bold">{{ this.messageToUser }}</h6>
      <ul>
        <li [ngClass]="{
              'time-slot-blue': event.enabled === true,
              'time-slot-grey': event.enabled === false
            }" *ngFor="let event of events" (click)="handleCalender(event)">
          {{ event.title }}
        </li>
      </ul>
    </div>
  </div>
</div>