export class FileUploadRequest {   
    file: File;
    doc: FileDetails;  
}

export class FileDetails {
    id_ubicacion: string;
    id_trabajador: string;
    id_tipo: string;
    filename: string;
    descripcion: string;
    fecha: string;
}
