import { Injectable } from '@angular/core';
import { OwlDateTimeIntl } from '@busacca/ng-pick-datetime';

// here is the default text string
@Injectable({
    providedIn: 'root',
})
export class OwlDateTimeSpanish extends OwlDateTimeIntl {
    /** A label for the cancel button */
    cancelBtnLabel = 'Cancelar';

    /** A label for the set button */
    setBtnLabel = 'Conjunto';
}
