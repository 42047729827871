export class CompanyInforTreeCompanyNode {
    id_branch: string;
    id_empresa: string;
    name_empresa: string;
    centers: CompanyInforTreeCenterNode[];
}

export class CompanyInforTreeCenterNode {
    id_branch: string;
    id_center: string;
    name_center: string;
    places: CompanyInforTreePlaceNode[];
}

export class CompanyInforTreePlaceNode {
    id_branch: string;
    id_place: string;
    name_place: string;
    workplaces: CompanyInforTreeWorkplaceNode[];
}

export class CompanyInforTreeWorkplaceNode {
    id_branch: string;
    id_workplace: string;
    name_workplace: string;
    materialAgents: CompanyInforTreeAgentNode[];
}

export class CompanyInforTreeAgentNode {
    id_branch: string;
    id_agent: string;
    name_agent: string;
}

