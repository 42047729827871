import { Component, OnInit } from '@angular/core';
import { CommonUiService } from '../../shared/services/common-ui.service';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit {
  hideMenu = true;

  constructor(commonUiService: CommonUiService) {

    commonUiService.toggleMenu.subscribe(() => {
      this.hideMenu = !this.hideMenu;
      localStorage.setItem('hideMenu', String(this.hideMenu));
    });
  }

  ngOnInit(): void {
    this.hideMenu = JSON.parse(localStorage.getItem('hideMenu'));
  }  

}
