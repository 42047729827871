<div class="salud-report">
    <div class="row header-title">
        <div class="col-md-8 col-lg-10 title-line s-w-70">
            <h5>Procedimientos</h5>
        </div>
        <div class="col-md-2">
            <a [routerLink]="['../pageindex']">Volver</a>
        </div>
    </div>
    <div>
        <table class="table">
            <caption></caption>
            <tbody >
                <tr *ngFor = "let item of ProcudresList">
                    <td>{{item.doc_maestro_txt}}</td>
                    <td class="proc-btn">

                        <button *ngIf="item.documento_exist==true && donloadLoadingId!=item.id_doc_maestro;else disableDescargar" type="button" class="btn-primary down-arrow btn-descrgar" (click)="onClickDescargar(item)" >
                            Descargar
                            <span class="fa fa-sort-desc" aria-hidden="true"></span>
                        </button>                        

                        <ng-template #disableDescargar>      
                            <button [disabled]="true" type="button" class="btn-primary down-arrow btn-descrgar btn-descrgar-disable">
                                Descargar
                                <span class="fa fa-sort-desc" aria-hidden="true"></span>
                            </button>
                        </ng-template>

                    </td>
                    <td class="proc-btn">
                        <button *ngIf="item.can_be_regenerated==true  && loadingId!=item.id_doc_maestro;else disableGenerar" type="button" class="btn-primary down-arrow btn-generar" (click)="onClickGenerar(item)">
                            Generar
                            <span class="fa fa-refresh" aria-hidden="true"></span>
                        </button>
                        <span *ngIf="loadingId==item.id_doc_maestro">esperar</span>
                        <ng-template #disableGenerar>
                            <button *ngIf="loadingId!=item.id_doc_maestro" [disabled]="true" type="button" class="btn-primary down-arrow btn-generando btn-generando-disable">
                                Generando
                                <span class="fa fa-clock-o" aria-hidden="true"></span>
                            </button>
                        </ng-template>
                    </td>
                </tr>
              
            </tbody>
        </table>
      
    </div>
</div>
