import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CompanyInformationDocumentListResponse } from '../model/company-information-document-list-response';
import { DocumentoOrderQuery } from '../model/documento-order-query';
import { Constants } from './constants';
import { AdvanceFilterRequest } from '../model/advance-filter-request';

@Injectable({
  providedIn: 'root'
})
export class CompanyInformationDocumentListService {

  private url = 'empresa/tree/documentos';
  public order_query_list: DocumentoOrderQuery[];

  constructor(private _httpClient: HttpClient) {
    this.order_query_list = [
      { id: 'NAME', description: 'Nombre Documento' },
      { id: 'DATE', description: 'Fecha' },
      { id: 'PATH', description: 'Directario' }
    ];
  }

  public getCompanyInfoDocumentList(_filter: AdvanceFilterRequest): Observable<CompanyInformationDocumentListResponse> {
    return this._httpClient.post<CompanyInformationDocumentListResponse>(Constants.apiRoot + this.url, _filter);
  }
}
