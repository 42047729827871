import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Constants } from './constants';
import { DocumentoFileResponse } from '../model/documento-file-response';
import { DocumentoFileQuery } from '../model/documento-file-query';
import { FileUploadResponse } from '../model/file-upload-response';

@Injectable({ providedIn: 'root' })
export class DocumentoFileService {

  url = 'empresa';

  constructor(
    private _httpClient: HttpClient) {
  }

  public getDocumentoFile(documento: DocumentoFileQuery): Observable<DocumentoFileResponse> {
    return this._httpClient.post<DocumentoFileResponse>(Constants.apiRoot + this.url + '/documento', documento);
  }

  public addDocument(documento: any): Observable<FileUploadResponse> {
    return this._httpClient.post<FileUploadResponse>(Constants.apiRoot + this.url + '/documento/add', documento);
  }

  public updateDocument(documento: any): Observable<any> {
    return this._httpClient.post<any>(Constants.apiRoot + this.url + '/documento/update', documento);
  }

  public deleteDocument(documento: any): Observable<any> {
    return this._httpClient.post<any>(Constants.apiRoot + this.url + '/documento/delete', documento);
  }

  public downloadFormacionFile(documento: DocumentoFileQuery): Observable<DocumentoFileResponse> {
    return this._httpClient.post<DocumentoFileResponse>(Constants.apiRoot + this.url + '/diplomaTrabajador' , documento);
  }

  // get category list for documents
  public getCategoryFolderList(): Observable<any>{
    return this._httpClient.post<any>(Constants.apiRoot + this.url + '/categorias', {});
  }

  //get sub category list for respective category
  public getSubCategoryFolderList(categoryId: any) : Observable<any>{
    return this._httpClient.post<any>(Constants.apiRoot + this.url + '/subcategorias', {id_categoria: categoryId});

  }

}
