import { PowerBiService } from './../../../core/power-bi.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { IReportEmbedConfiguration,models } from 'powerbi-client';
import { PowerBIReportEmbedComponent } from 'powerbi-client-angular';
import { Observable, Observer } from 'rxjs';
import { CommonUiService } from 'src/app/shared/services/common-ui.service';

@Component({
  selector: 'app-power-bi-report',
  templateUrl: './power-bi-report.component.html',
  styleUrls: ['./power-bi-report.component.scss']
})
export class PowerBiReportComponent implements OnInit {
  @ViewChild(PowerBIReportEmbedComponent) reportObj!: PowerBIReportEmbedComponent;
  company: any;
  reportId:Number;
  isLoading:boolean;
  embedConfigObs: Observable<any>;
  reportClass = 'report-container reportClass iframe-boder';
  readReportId:string;
  readWorkspaceId:string;

  reportConfig: IReportEmbedConfiguration = {
    type: 'report',
    embedUrl: undefined,
    tokenType: models.TokenType.Embed,
    accessToken: undefined,
    settings: undefined,
  };

  reportObject : any;
  errorMessage: any;

  constructor(private _powerBiService : PowerBiService, private commonUiService: CommonUiService) { 
    this.company = JSON.parse(localStorage.getItem('company'));
  }

  ngOnInit(): void {    
    this.loadPowerBIReport();
  }

  loadPowerBIReport() {
    this._powerBiService.getPowerBIData(this.company.empresa_id, 1 ).subscribe(response => {
      this.isLoading = false;
      let embedConfig = { 
        type: 'report',
        id: response.EmbedReport[0].ReportId,
        embedUrl:response.EmbedReport[0].EmbedUrl,
        accessToken: response.EmbedToken.Token, 
        tokenType: models.TokenType.Embed,
        hostname: "https://app.powerbi.com",
      } 
      this.embedConfigObs = new Observable<any>((observer: Observer<any>) => {
        observer.next(embedConfig)
        
      });
    }, _error => {
      //this.commonUiService.showFailToast("Error","Informe de error al cargar");
      this.errorMessage = 'Report Not Available';
      this.isLoading = false;
    });
  }
  
 
  
}
