import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { DocumentoFileService } from 'src/app/core/documento-file.service';
import { ExcelExportService } from 'src/app/core/excel-export.service';
import { TrabajadoresService } from 'src/app/core/trabajadores.service';
import { Utils } from 'src/app/core/utils';
import { CentroItem } from 'src/app/model/centro-item';
import { DocCentTrabResponse } from 'src/app/model/doccenttrab-response';
import { NgxPaginationConfig } from 'src/app/model/ngx-pagination-config';
import { TrabajadorItem } from 'src/app/model/trabajador-item';
import { TrabajadoresRequest } from 'src/app/model/trabajadores-request';
import { TrabajadoreDetItem } from 'src/app/model/trabajadores-respond';
import { CommonUiService } from 'src/app/shared/services/common-ui.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ErpPersonService } from 'src/app/core/erp-person.service';
import { TrabajadoreEditComponent } from '../trabajadore-edit/trabajadore-edit.component';
import { CentersListComponent } from '../../auto-reversa/centers-list/centers-list.component';
import { CentrosytrabajadoresListService } from 'src/app/core/centrosytrabajadores-list.service';
import { AutoReversaService } from 'src/app/core/auto-reversa.service';
import { CommonMessageComponent } from 'src/app/shared/components/common-message/common-message.component';
import { EmployeeRegistrationComponent } from '../../auto-reversa/employee-registration/employee-registration.component';
import { SharedDataService } from 'src/app/shared/services/shared-data.service';
import { Router } from '@angular/router';
import { TraningCertificatesComponent } from '../traning-certificates/traning-certificates.component';
import { certificates } from 'src/app/model/TrabCertificatesRespond';

interface SortState {
  column: string;
  direction: 'asc' | 'desc';
}
@Component({
  selector: 'app-trabajadore-active',
  templateUrl: './trabajadore-active.component.html',
  styleUrls: ['./trabajadore-active.component.scss']
})
export class TrabajadoreActiveComponent implements OnInit {
  @Input() activeEvent: Observable<boolean>;

  pageAttrResp: DocCentTrabResponse;
  error: string;
  isActive: boolean = true;

  filterCenters: CentroItem[];
  selectedCenter: CentroItem;
  selectedEmployee: TrabajadorItem;
  trabajadoreRequest: TrabajadoresRequest;
  trabActiveList: TrabajadoreDetItem[];
  private eventsSubscription: Subscription;
  authorized: boolean= false;

  company: any;
  pendingRowcount = 0;
  filesArray: string[];
  accessData: any;

  workCentersRequestData = {
    empresa_id: null,
    incl: null
  }

  public paginationActiveConfig = new NgxPaginationConfig();

  public activeForm = new FormGroup({
    centro: new FormControl(null),
    trabajador: new FormControl(null),
    checkaptos: new FormControl('todos')
  });
  sortState: SortState = { column: '', direction: 'asc' };
  orderByKeyWord: any;
  isLoading: any;

  constructor(private trabajadoresService: TrabajadoresService,
    private router: Router,
    public _commonUiService: CommonUiService,
    public _documentoFileService: DocumentoFileService,
    public _excelExportService: ExcelExportService,
    private autoreversaService: AutoReversaService,
    public _erpPersonService: ErpPersonService,
    private sharedService: SharedDataService,
    public _centrosytrabajadoresService: CentrosytrabajadoresListService,
    private modalService: NgbModal) {
    this.company = JSON.parse(localStorage.getItem('company'));
    this.sharedService.getAppointmentClick().subscribe(() => {
      this.paginationActiveConfig.currentPage = 1;
      this.getTrabajadore();
    });

    if (localStorage.getItem("role") != null) {
      let role= JSON.parse(localStorage.getItem('role'));
      this.authorized = role.authorized;
  }
  this.sortState.direction = 'asc';
  }

  ngOnInit() {
    this.trabActiveList = [];
    this.paginationActiveConfig.id = 'pending-active-list-pagination';
    this.paginationActiveConfig.currentPage = 1;
    this.paginationActiveConfig.itemsPerPage = 10;
    this.getPageAttributes();
    this.getTrabajadore();
    this.eventsSubscription = this.activeEvent.subscribe(res => {
      this.isActive = res;
      if (this.isActive) {
        this.getTrabajadore();
      }
    });
  }

  ngOnDestroy() {
    this.eventsSubscription.unsubscribe();
  }

  getTrabajadore() {
    let checkaptos = this.activeForm.controls.checkaptos.value;

    this.trabajadoreRequest = {
      empresa_id: this.company.empresa_id,
      centro_id: this.activeForm.controls.centro.value,
      trabajador_txt: this.activeForm.controls.trabajador.value,
      activos: this.isActive,
      aptos_check: checkaptos == 'aptos' ? true : false,
      no_aptos_check: checkaptos == 'noaptos' ? true : false,
      todos_check: checkaptos == 'todos' ? true : false,
      order_by: this.orderByKeyWord
    }
    this.trabajadoresService.getTrabajadores(this.trabajadoreRequest).subscribe(res => {
      if (res) {
        if (res.webError == null) {
          this.trabActiveList = res.data;
          this.paginationActiveConfig.totalItems = res.data.length;
          setTimeout(() => {
            this.paginationActiveConfig.totalItems = res.data.length;
          }, 1000);
          this.isLoading = false;
        } else {
          this._commonUiService.showFailToast(" ", res.webError.messageToUser);
        }
      }
    });
  }

  getPageAttributes(): void {
    this.workCentersRequestData.empresa_id = this.company.empresa_id;
    this._centrosytrabajadoresService.getWorkCenterList(this.workCentersRequestData).subscribe(res => {
      if (res.webError != null) {
        this.error = res.webError.messageToUser;
      } else {
        this.filterCenters = res.data;
      }
    });
  }

  public pendingPageChanged(pageNo: number): void {
    this.paginationActiveConfig.currentPage = pageNo;
  }

  onSearchTrabajador() {
    this.getTrabajadore();
  }

  selectCentro() {
    this.getTrabajadore();
  }

  changeAptos() {
    this.getTrabajadore();
  }

  onCancelTrabajadore(trabajadorId: string) {
    let trabajadorReq = {
      empresa_id: this.company.empresa_id,
      trabajador_id: trabajadorId
    }

    this.trabajadoresService.disableTrabajadores(trabajadorReq).subscribe(res => {
      if (res) {
        if (res.webError == null) {
          this._commonUiService.showSuccessToast("Deshabilitado con éxito !!!");
          this.getTrabajadore();
        } else {
          this._commonUiService.showFailToast(" ", res.webError.messageToUser);
        }
      }
    });
  }

  downloadExcel() {
    let dataToExcel = [];
    this.trabActiveList.forEach(e => {
      dataToExcel.push(
        {
          NIF: e.trabajador_nif,
          NombreTrabajador: e.trabajador_txt,
          Telefono: e.trabajador_telef,
          Email: e.trabajador_email,
          Aptitud: e.aptitud_tiene,
          Caducidad: e.aptitud_caducidad,
          Próximacita: e.siguiente_cita
        }
      )
    });
    this._excelExportService.exportAsExcelFile(dataToExcel, "Trabajadores_activos");
  }

  onClickAPT(aptitud_doc: string) {
    let documentoReq = {
      id_documento: aptitud_doc
    }
    this._documentoFileService.getDocumentoFile(documentoReq).subscribe(res => {
      if (res != null) {
        if (res.webError != null) {
          this._commonUiService.showFailToast(" ", res.webError.messageToUser);
        }
        else {
          const linkSource = 'data:application/pdf;base64,' + res.data.fichero + '\n';
          const downloadLink = document.createElement('a');
          const fileName = res.data.nombre;
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        }
      }
    }, error => this.error = Utils.formatError(error));
  }

  onEditTrabajadore(trabajador: TrabajadoreDetItem) {
    const modalRef = this.modalService.open(TrabajadoreEditComponent, { centered: true, size: 'lg' });
    modalRef.componentInstance.status = 'edit';
    modalRef.componentInstance.trabajador = trabajador;
    modalRef.result.then(_close => {
    }, _dismiss => {
      this.getTrabajadore();
    });

  }

  newTrabajadore() {
    const modalRef = this.modalService.open(TrabajadoreEditComponent, { centered: true, size: 'lg' });
    modalRef.componentInstance.status = 'new';
    modalRef.componentInstance.isSaved.subscribe((res) => {
      if (res == true) {
        this.getTrabajadore();
      }
    });
  }

  onEditProximacita(trabajador: TrabajadoreDetItem) {
    this.getGainAccess(trabajador);
  }

  onKeyUpTrabajadorActive(event) {
    if (event.target.value == "") {
      this.getTrabajadore();
    }
  }

  getGainAccess(trabajador: TrabajadoreDetItem) {
    const request = {
      id_company: this.company.empresa_id,
      dni: trabajador.trabajador_nif,
      fecha_nacimiento: this._commonUiService.financialdateToString(trabajador.trabajador_nacimiento),
    };

    this.autoreversaService.getGainAccess(request).subscribe(res => {
      if (res && res.webError == null) {
        this.accessData = res.data;
        this.handelFlow(request, trabajador);
      } else {
        this._commonUiService.showFailToast("Error ", res.webError.messageToUser);
      }
    });
  }

  handelFlow(request: any, trabajador: TrabajadoreDetItem) {
    if (this.accessData) {
      //All are valid
      if (this.accessData.dni_found && this.accessData.dni_match && this.accessData.company_can_selfappt && this.accessData.company_unique_found) {
        this.navigateCenters(trabajador, request);
      }// cannot self appoint 
      else if ((this.accessData.dni_found && this.accessData.dni_match && this.accessData.company_unique_found) && (this.accessData.company_can_selfappt == null
        || !this.accessData.company_can_selfappt)) {
        this.navigateUserMessage();
      }// dni found or found in more than one company
      else if (!this.accessData.dni_found || !this.accessData.company_unique_found) {
        if (!this.accessData.message_to_user) {
          this.navigateRegistration();
        }
        else {
          this.navigateUserMessage();
        }
      }//birthday not found
      else if (this.accessData.dni_found && !this.accessData.dni_match) {
        this._commonUiService.showFailToast("Error ", this.accessData.message_to_user);
      }
    }
  }

  navigateCenters(trabajador: TrabajadoreDetItem, request: any) {
    const formData = request;
    formData['persona_id'] = this.accessData.persona_id;
    formData['email'] = trabajador.trabajador_email;
    formData['telef'] = trabajador.trabajador_telef;
    localStorage.setItem('employeeDetails', JSON.stringify(formData));

    const modalRef = this.modalService.open(CentersListComponent, { centered: true, size: 'xl' });
    modalRef.componentInstance.trabajador = trabajador;
    modalRef.componentInstance.accessData = this.accessData;
  }

  navigateUserMessage() {
    const modalRef = this.modalService.open(CommonMessageComponent, { centered: true });
    modalRef.componentInstance.message = btoa(this.accessData.message_to_user);
    modalRef.componentInstance.fromUrl = btoa(this.router.url);
  }

  navigateRegistration() {
    this.modalService.open(EmployeeRegistrationComponent, { centered: true });
  }

  getCertificates(trabajador: TrabajadoreDetItem) {
    var request = {
      empresa_id: this.company.empresa_id,
      trabajador_id: trabajador.trabajador_id,
      show_formaciones: true
    }     

    this.trabajadoresService.getCertificates(request).subscribe(res => {
      if (res && res.webError == null) {
        this.openCertificateModal(res.data , trabajador);
      } else {
        this._commonUiService.showFailToast(" ", res.webError.messageToUser);
      }
    });  
  }

  // open renuncia calendar date picker
  openCalendarRenuncia(datePicker: any){
    datePicker.open();
  }

  //save selected renuncia date
  onDateSelected(event: any, item: any){
    const fecha_renuncia = event.value._d;

    //format the date
    const year = fecha_renuncia.getFullYear();
    const month = ('0' + (fecha_renuncia.getMonth() + 1)).slice(-2);
    const day = ('0' + fecha_renuncia.getDate()).slice(-2);

    const formattedDate = `${year}${month}${day}`;
    const trabajadorId = item.trabajador_id
    let dataModel = {
      id_trabajador:trabajadorId,
      fecha_renuncia: formattedDate
    }

    this.trabajadoresService.saveRenunciaDate(dataModel).subscribe((item) =>{
      this.getTrabajadore();
    },
  err=>{
    console.error(err);
  }); 
  }

  //remove renuncia date
  removeRenunciaDate(item: any) {
    const trabajadorId = item.trabajador_id;
    let dataModel = {
      id_trabajador: trabajadorId,
      fecha_renuncia: ''
    };
    this._commonUiService.showConfirmWithoutIcon('', '¿Está seguro que desea eliminar esta indicación?<br><br>Si lo hace, ya no se indicará fecha de renuncia para este trabajador.').then((result) => {
      if (result) {
        this.trabajadoresService.saveRenunciaDate(dataModel).subscribe(() => {
          this.getTrabajadore();
        }, (err) => {
          console.error(err);
        });
      }
    });
  } 

  openCertificateModal(certificateList: certificates[] , trabajador: TrabajadoreDetItem) {
    const modalRef = this.modalService.open(TraningCertificatesComponent, { centered: true, size: 'lg' });
    modalRef.componentInstance.formacionesList = certificateList;
    modalRef.componentInstance.trabajador = trabajador;
  }

  //sort data when clicking column headers
  sortColumn(column: string) {

    this.isLoading = true;
    if (this.sortState.column === column) {
      this.sortState.direction = this.sortState.direction === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortState.column = column;
      this.sortState.direction = 'asc';
    }
    this.sortData(column);
  }

    //call api using sort data
  sortData(column: any) {
    if(this.sortState.column === column){
      if(this.sortState.direction == 'asc'){
        this.orderByKeyWord = column;
      }else{
        this.orderByKeyWord = column + '_DESC' 
      }
    }else{
      this.orderByKeyWord = column;
    }
    this.getTrabajadore();
  }

}
