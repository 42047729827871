<div>
  <div class="row calender-date">
    <button (click)="moveBackwards()">
      <span class="fa fa-chevron-left"></span>
    </button>
    <span class="mx-2">{{ this.weekStartEndDates }}</span>
    <button (click)="moveForward()">
      <span class="fa fa-chevron-right"></span>
    </button>
  </div>
  <div class="row calender-box">
    <ul>
      <li class="days" *ngFor="let day of days">{{ day }}</li>
      <li *ngFor="let date of weekList" (click)="setSelected(date)" [ngClass]="!isPast(date) && this.selectedDay.toDateString() == formatDate(date)?.toDateString()
            ? 'selected' : isPast(date) ? 'disabled-date' : ''"> {{ returnDateDay(date) }}
      </li>
    </ul>
  </div>
</div>