export class Constants {

	// important: tell us what the final url will be !!!!!!!!!!!!!!!!!!!!!!!!!!!
  public static clientRoot = 'https://areaempresa.geseme.com/';	
  //public static clientRoot = 'http://localhost:4200/';

  public static apiRoot = 'https://erpapipre.geseme.com/';	//'https://api-pre.geseme.com/';
	public static stsAuthority =  'https://stspre.geseme.com/'; //'https://it.geseme.com/';

	//public static apiRoot = 		'http://localhost:8084/'
	//public static stsAuthority = 	'http://localhost:8083/';

	public static clientId = 'erp' //'aeapp';
	public static clientId_scope = 'openid profile ae_read usr_read erp_data_access usr_auth_read selfappt_access selfappt_upd'; //'openid profile ae_read usr_read erp_data_access'
	public static clientId_response_type = 'code';				//PKCE 

	// if any of these two values are present for a user, they overrides any other permission
	public static readonly AUTH_NO_ACCESS = -1;					// DENIED ACCESS for any app
	public static readonly AUTH_ADMIN = 0;						// ADMIN for all apps

	// specific values only for Area de Empresa web app
	public static readonly AUTH_AREAENTERPR_ADMIN = 200;			//Area Empresa - admin
	public static readonly AUTH_AREAENTERPR_NO_ACCESS = 201;		//Area Empresa - denied access to Area Empresa app
	public static readonly AUTH_AREAENTERPR_FULL_EDIT = 202;		//Area Empresa - [NOT USED]		-user can query over all documents from all enterprises												
	public static readonly AUTH_AREAENTERPR_ONLY_MINE = 203;		//Area Empresa - [DEFAULT]		-logged user can get documents from his enterprise

	//public static  powerBIRoot='http://localhost:18731/api/'; 							//Power BI Report API Local
	//public static  powerBIRoot='https://qa.token.arempresa.qualitapps.com/api/'; 		//Power BI Report API QA
	//public static  powerBIRoot='https://uat.token.valimed.qualitapps.com/api/'; 		//Power BI Report API UAT
	public static  powerBIRoot='https://token_arempresa.geseme.com/api/'; 		        //Power BI Report API Live
}
