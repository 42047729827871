import { Constants } from './../constants';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PowerBiService {
  powerbiurl="PowerBI";

  constructor(private _httpClient: HttpClient) {
  }

  public getPowerBIData(userid: string, reportId: number): Observable<any> {
    return this._httpClient.get<any>(Constants.powerBIRoot + this.powerbiurl + "/GetEmbedInfoWithUser/" + userid + "/" + reportId);
  }

}
