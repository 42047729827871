import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-common-message',
  templateUrl: './common-message.component.html',
  styleUrls: ['./common-message.component.scss']
})
export class CommonMessageComponent implements OnInit {
  @Input() message: string;
  @Input() fromUrl: string;
  
  constructor(public modal: NgbActiveModal) { }
 
  ngOnInit(): void {
      this.message = this.message !=null ? atob(this.message || '') : '';
      this.fromUrl = this.fromUrl !=null ? atob(this.fromUrl || '') : '';   
  }
}
