
  <div class="modal-body">
    <div class="row pl-4 pb-3">
      <div class="col-md-10 text-left pl-0">
        <h5 class="font-weight-bold mb-4">Escoge tu centro:</h5>
      </div>
      <div class="col-md-2">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">
          <span aria-hidden="true">×</span>
        </button>
      </div>
    </div>
    
    <div class="row ml-1">
      <div *ngFor="let center of listOfCenters; index as i" class="col-lg-3 col-xl-3 col-md-3 col-sm-12 center-section"
      (click)="onSelectCenter(center.agenda_id, center.centro_id, center.reco_id)">
        <h6 class="font-weight-bold mb-2">{{ center.agenda_nombre }}</h6>
        <label
          [innerHtml]="replaceNewlineWithBr(center.agenda_web_comments)"
        ></label>
      </div>
    </div>
  </div>

