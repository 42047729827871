import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Constants } from '../constants';
import { AllowCompaniesResponse } from '../model/allow-companies-response';
import { ComapnyRequest } from '../model/comapny-request';


@Injectable({
  providedIn: 'root'
})
export class CompaniesAllowedService {

  url = "lists/companies-allowed";

  constructor(private _httpClient: HttpClient){    
  }

  public getAllowedCompanyList(filter: ComapnyRequest): Observable<AllowCompaniesResponse> {
    return this._httpClient.post<AllowCompaniesResponse>(Constants.apiRoot + this.url , filter);
  }

}
