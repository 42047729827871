import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-common-calender',
  templateUrl: './common-calender.component.html',
  styleUrls: ['./common-calender.component.scss']
})
export class CommonCalenderComponent implements OnInit {

  days = ['L', 'M', 'M', 'J', 'V', 'S', 'D'];

  @Input() startDay: number = 1;
  @Input() startDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1);
  @Input() selectedDay: any = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1);
  @Input() locale: string = 'es-ES';


  weekStartEndDates = "";
  weekList: Array<object> = [];
  weekOffset: number = 0

  @Output() selected = new EventEmitter();

  constructor() {
    this.createWeek();
  }

  ngOnInit() {
    this.setSelected(this.startDate);

  }

  dates(current: Date) {
    var week = new Array();
    current.setDate(current.getDate() - current.getDay() + this.startDay);
    for (var i = 0; i < 7; i++) {
      week.push(
        new Date(current)
      );
      current.setDate(current.getDate() + 1);
    }
    return week;
  }

  moveWeek(date: Date, n: number): Date {
    let d = new Date(date);
    return new Date(d.setDate(d.getDate() + (7 * n)));
  }

  moveForward() {
    this.weekOffset += 1;
    this.createWeek();
  }

  moveBackwards() {
    this.weekOffset -= 1;
    this.createWeek();
  }

  createWeek() {
    this.weekList = [];
    var date = this.moveWeek(this.startDate, this.weekOffset)
    var week = this.dates(date)
    for (var i = 0; i < 7; i++) {
      let day = week[i];
      this.weekList.push(day)
      if (i == 0) {
        this.weekStartEndDates = day.toLocaleDateString(this.locale, { year: 'numeric', month: 'short', day: 'numeric' })
      }
      if (i == 6) {
        this.weekStartEndDates = this.weekStartEndDates + ' - ' + day.toLocaleDateString(this.locale, { year: 'numeric', month: 'short', day: 'numeric' })
      }
    }
  }

  setSelected(date: any) {
    if (!this.isPast(date)) {
      this.selectedDay = date;
      this.selected.emit(date);
    }
  }

  returnDateDay(date: any) {
    return new Date(date).getDate()
  }

  formatDate(date: any) {
    return new Date(date);
  }

  isPast(date: any) {
    if (date <= new Date()) {
      return true
    }
    return false
  }
}



