
<div *ngIf="isLoading">
  <app-spinner></app-spinner>
</div>

<form name="trabajadoresForm" [formGroup]="activeForm">
    <div class="row">
        <div class="col-md-3 d-flex s-m-t-10">
            <input type="text" placeholder="Trabajador" name="search" class="search-input w-100" formControlName="trabajador" (keyup)="onKeyUpTrabajadorActive($event)">
            <button type="submit" class="search-icon-btn" (click)="onSearchTrabajador()">
                <span class="fa fa-search"></span>
            </button>
        </div>
        <div class="col-md-3 col-lg-3 s-m-t-10">
            <ng-select #select (close)="select.blur()" loadingText="Cargando..." notFoundText="No hay resultados"
                [items]="filterCenters" id="filter-center" name="filter-center" [clearable]="true"
                [virtualScroll]="true" bindLabel="nombre_centro" bindValue="id_centro" placeholder="Centro de Trabajo"
                formControlName="centro" (change)="selectCentro()">
            </ng-select>
        </div>
        <div class="col-md-3 s-m-t-10">
            <div class="row">
                <div class="form-group form-check form-check-inline border border-secondar">
                
                    <div class="checkbox-inline mr-2">
                        <input type="radio" class="form-check-input" name="checkaptos" id="aptos" formControlName="checkaptos" value="aptos"
                        (change)="changeAptos()">
                        <label for="aptos" class="form-check-label"> Aptitud Vigente </label>
                    </div>
                    <div class="checkbox-inline mr-2 ml-2">
                        <input type="radio" class="form-check-input" name="checkaptos" id="noaptos" formControlName="checkaptos" value="noaptos"
                        (change)="changeAptos()">
                        <label for="noaptos" class="form-check-label"> Sin Aptitud </label>
                    </div>
                    <div class="checkbox-inline mr-2 ml-2">
                        <input type="radio" class="form-check-input" name="checkaptos" id="todos" formControlName="checkaptos" value="todos"
                        (change)="changeAptos()">
                        <label for="todos" class="form-check-label"> TODOS </label> <span> </span>
                    </div>
                </div>
            </div>            
        </div>
        <div class="col-md-3 s-m-t-10 text-right">
            <div class="btn-group" role="group" aria-label="Basic example">
                <button *ngIf="authorized"  type="button" class="btn-primary mr-2" (click)="newTrabajadore()">Añadir Trabajador</button>
                <button type="button" class="btn-primary" (click)="downloadExcel()">Descargar Excel</button>
            </div>                            
        </div>
    </div>
    
    <div class="row mt-2">
      <div class="col-md-12 d-flex s-m-t-10">
        <table class="table tbl-trabajadore">
            <caption></caption>
            <thead class="thead-light">
              <tr>
                <th *ngIf="authorized"></th>
                <th (click)="sortColumn('NIF')">
                  NIF 
                  <i *ngIf="sortState.column === 'NIF' && sortState.direction === 'asc'" class="fa fa-sort-asc ml-2"></i>
                  <i *ngIf="sortState.column === 'NIF' && sortState.direction === 'desc'" class="fa fa-sort-desc ml-2"></i>
                  <i *ngIf="sortState.column !== 'NIF'" class="fa fa-sort-asc ml-2"></i>
                </th>
                <th (click)="sortColumn('NAME')">
                  Nombre Trabajador 
                  <i *ngIf="sortState.column === 'NAME' && sortState.direction === 'asc'" class="fa fa-sort-asc ml-2"></i>
                  <i *ngIf="sortState.column === 'NAME' && sortState.direction === 'desc'" class="fa fa-sort-desc ml-2"></i>
                  <i *ngIf="sortState.column !== 'NAME'" class="fa fa-sort-asc ml-2"></i>
                </th>
                <th (click)="sortColumn('TEL')">Telefono
                  <i *ngIf="sortState.column === 'TEL' && sortState.direction === 'asc'" class="fa fa-sort-asc ml-2"></i>
                  <i *ngIf="sortState.column === 'TEL' && sortState.direction === 'desc'" class="fa fa-sort-desc ml-2"></i>
                  <i *ngIf="sortState.column !== 'TEL'" class="fa fa-sort-asc ml-2"></i>
                </th>
                <th (click)="sortColumn('MAIL')">E-Mail
                  <i *ngIf="sortState.column === 'MAIL' && sortState.direction === 'asc'" class="fa fa-sort-asc ml-2"></i>
                  <i *ngIf="sortState.column === 'MAIL' && sortState.direction === 'desc'" class="fa fa-sort-desc ml-2"></i>
                  <i *ngIf="sortState.column !== 'MAIL'" class="fa fa-sort-asc ml-2"></i>
                </th>
                <th (click)="sortColumn('SENSIBLE')">Sensible
                  <i *ngIf="sortState.column === 'SENSIBLE' && sortState.direction === 'asc'" class="fa fa-sort-asc ml-2"></i>
                  <i *ngIf="sortState.column === 'SENSIBLE' && sortState.direction === 'desc'" class="fa fa-sort-desc ml-2"></i>
                  <i *ngIf="sortState.column !== 'SENSIBLE'" class="fa fa-sort-asc ml-2"></i>
                </th>
                <th style="display: none;" (click)="sortColumn('DISCAPACIDAD')">Discapacidad
                  <i *ngIf="sortState.column === 'DISCAPACIDAD' && sortState.direction === 'asc'" class="fa fa-sort-asc ml-2"></i>
                  <i *ngIf="sortState.column === 'DISCAPACIDAD' && sortState.direction === 'desc'" class="fa fa-sort-desc ml-2"></i>
                  <i *ngIf="sortState.column !== 'DISCAPACIDAD'" class="fa fa-sort-asc ml-2"></i>
                </th>
                <th (click)="sortColumn('RENUNCIA')">Renuncia
                  <i *ngIf="sortState.column === 'RENUNCIA' && sortState.direction === 'asc'" class="fa fa-sort-asc ml-2"></i>
                  <i *ngIf="sortState.column === 'RENUNCIA' && sortState.direction === 'desc'" class="fa fa-sort-desc ml-2"></i>
                  <i *ngIf="sortState.column !== 'RENUNCIA'" class="fa fa-sort-asc ml-2"></i>
                </th>
                <th (click)="sortColumn('APTO')">Aptitud
                  <i *ngIf="sortState.column === 'APTO' && sortState.direction === 'asc'" class="fa fa-sort-asc ml-2"></i>
                  <i *ngIf="sortState.column === 'APTO' && sortState.direction === 'desc'" class="fa fa-sort-desc ml-2"></i>
                  <i *ngIf="sortState.column !== 'APTO'" class="fa fa-sort-asc ml-2"></i>
                </th>
                <th (click)="sortColumn('CADUCA')">Caducidad
                  <i *ngIf="sortState.column === 'CADUCA' && sortState.direction === 'asc'" class="fa fa-sort-asc ml-2"></i>
                  <i *ngIf="sortState.column === 'CADUCA' && sortState.direction === 'desc'" class="fa fa-sort-desc ml-2"></i>
                  <i *ngIf="sortState.column !== 'CADUCA'" class="fa fa-sort-asc ml-2"></i>
                </th>
                <th (click)="sortColumn('PROXIMA')">Próxima cita agendada
                  <i *ngIf="sortState.column === 'PROXIMA' && sortState.direction === 'asc'" class="fa fa-sort-asc ml-2"></i>
                  <i *ngIf="sortState.column === 'PROXIMA' && sortState.direction === 'desc'" class="fa fa-sort-desc ml-2"></i>
                  <i *ngIf="sortState.column !== 'PROXIMA'" class="fa fa-sort-asc ml-2"></i>
                </th>
                <th>Certificados</th>
                <th>Inactivar</th>
              </tr>
            </thead>        
            <tbody>
                <tr *ngFor="let item of trabActiveList | paginate: paginationActiveConfig;">
                    <td *ngIf="authorized" >
                        <a class="pb-2" (click)="onEditTrabajadore(item)">
                            <span class="fa fa-edit tbl-row-action"></span>
                        </a>
                    </td>
                    <td>{{item.trabajador_nif}}</td>
                    <td>{{item.trabajador_txt}}</td>
                    <td>{{item.trabajador_telef}}</td>
                    <td>{{item.trabajador_email}}</td>
                    <td><i class="fa {{ item.persona_sensible == true ? 'fa-check' : '' }}"></i></td>
                    <td style="display: none;"><i class="fa {{ item.persona_discapacitada == true ? 'fa-check' : '' }}"></i></td>
                    <td>
                        <i *ngIf="!item?.fecha_renuncia" class="fa fa-edit tbl-row-action" (click)="openCalendarRenuncia(renunciaDate)"></i>
                        <i *ngIf="item?.fecha_renuncia" class="fa fa-times tbl-row-cancel" (click)="removeRenunciaDate(item)"></i>
                        {{ _commonUiService.dateStringFormat(item.fecha_renuncia) }}          
                        <input  style="visibility: hidden; width: 1px !important;" [owlDateTimeTrigger]="renunciaDate" [owlDateTime]="renunciaDate" (dateTimeChange)="onDateSelected($event, item)" #renunciaDateInput>
                        <owl-date-time #renunciaDate [pickerType]="'calendar'"></owl-date-time>
                    </td>                   
                    <td>
                        <a *ngIf="item.aptitud_tiene" class="btn btn-primary btn-icon" (click)="onClickAPT(item.aptitud_doc)">
                            APT.<span class="fa fa-sort-desc" aria-hidden="true"></span> 
                        </a>
                    </td>
                    <td> {{ _commonUiService.dateStringFormat(item.aptitud_caducidad)}} </td>
                    <td class="text-gray" *ngIf="item.siguiente_activo" (click)="onEditProximacita(item)">
                            <i class="fa fa-edit tbl-row-action">  </i>                           
                            {{ _commonUiService.dateStringFormat(item.siguiente_cita)}} 
                    </td>
                    <td *ngIf="!item.siguiente_activo">
                        {{ _commonUiService.dateStringFormat(item.siguiente_cita)}}
                    </td>
                    <td>
                        <a class="btn btn-primary btn-icon" (click)="getCertificates(item)">
                            VER 
                        </a>
                    </td>
                    <td>
                        <a class="pb-2" (click)="onCancelTrabajadore(item.trabajador_id)">
                            <span class="fa fa-times tbl-row-cancel"></span>
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>
    
      </div>                                    
    </div>
    
    <div class="row">
      <!-- pagination -->
      <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12 pagination-div">
          <div class="d-flex pl-0">
              <pagination-controls previousLabel="Anterior" nextLabel="Siguiente" id="pending-active-list-pagination" 
                  (pageChange)="pendingPageChanged($event)" (pageBoundsCorrection)="pendingPageChanged($event)">
              </pagination-controls>
              <span class="pagination-font float-left pt-1"> Hay {{ paginationActiveConfig.totalItems }} Trabajadores activos registrados </span>
          </div>
      </div>
    </div>
  </form>     
