import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AutoReversaService } from 'src/app/core/auto-reversa.service';
import { TrabajadoreDetItem } from 'src/app/model/trabajadores-respond';
import { CommonUiService } from 'src/app/shared/services/common-ui.service';
import { CalenderComponent } from '../calender/calender.component';
import { CancelarReservaComponent } from '../cancelar-reserva/cancelar-reserva.component';
import { CommonMessageComponent } from 'src/app/shared/components/common-message/common-message.component';
import { EmployeeRegistrationComponent } from '../employee-registration/employee-registration.component';

@Component({
  selector: 'app-centers-list',
  templateUrl: './centers-list.component.html',
  styleUrls: ['./centers-list.component.scss']
})
export class CentersListComponent implements OnInit {
  listOfCenters: any = [];
  company: any;

  @Input() trabajador: TrabajadoreDetItem;
  @Input() accessData: any;

  constructor(private autoreversaService: AutoReversaService,
    private _commonUiService: CommonUiService,
    private modalService: NgbModal,
    public modal: NgbActiveModal,
    private router: Router,
    private sanitizer: DomSanitizer) {
    this.company = JSON.parse(localStorage.getItem('company'));
  }

  ngOnInit(): void {
    if (!this.trabajador) {
      this.trabajador = JSON.parse(localStorage.getItem("trabajador"));
    } else {
      localStorage.setItem("trabajador", JSON.stringify(this.trabajador));
    }
    this.getAgendas();
  }

  getAgendas() {
    var personDetails = {
      company_id: this.accessData.company_id,
      dni: this.trabajador.trabajador_nif,
      fecha_nacimiento: this.dateToString(this.trabajador.trabajador_nacimiento)
    }

    this.autoreversaService.getAgendas(personDetails).subscribe(res => {
      if (res.webError == null && res.data) {
        this.listOfCenters = res.data;
      }
      else {
        this._commonUiService.showWarningMessage(res.webError.messageToUser);
      }
    });
  }

  dateToString(newDate: string) {
    let day = newDate.substring(0, 2);
    let month = newDate.substring(3, 5);
    let year = newDate.substring(6, 10);

    return year + month + day;
  }

  replaceNewlineWithBr(text: any) {
    if (text) {
      return this.sanitizer.bypassSecurityTrustHtml(text.replace(/(?:\n\r|\\n\\r|\r|\n)/g, '<br>'));
    }
  }

  onSelectCenter(agendaId: number, centroId: number, reco_id: number) {
    this.storeWorkerDetails(agendaId, centroId, reco_id);

    if (agendaId > 0) {
      this.modal.dismiss();
      this.navigateCalender();
    }
    else if (agendaId < 0) {
      this.modal.dismiss();
      this.navigateCancelarreserva();
    }
    else if (agendaId == 0) {
      const appointmentDetails = {
        "attribs": {
          "agenda_id": agendaId,
          "company_id": this.accessData.company_id,
          "centro_id": centroId,
        },
        "persona": {
          "dni": this.trabajador.trabajador_nif,
          "fecha_nacimiento": this.dateToString(this.trabajador.trabajador_nacimiento),
        }
      };
      this.autoreversaService.addAppointment(appointmentDetails).subscribe(appointmentResponse => {
        if (appointmentResponse.webError == null) {
          this.navigateUserMessage();
        }
      });
    }
    else if (agendaId == 0) {
      this.navigateRegistration();
    }
  }

  storeWorkerDetails(agendaId: number, centroId: number, reco_id: number) {
    let userDetails = JSON.parse(localStorage.getItem("employeeDetails"));

    if (!userDetails) {
      const details = {
        agenda_id: agendaId,
        centro_id: centroId,
        reco_id: reco_id,
        company_id: this.accessData.company_id,
        persona_id: this.accessData.persona_id,
        dni: this.trabajador.trabajador_nif,
        email: this.trabajador.trabajador_email,
        telef: this.trabajador.trabajador_telef,
        fecha_nacimiento: this.dateToString(this.trabajador.trabajador_nacimiento),
      }
      userDetails = details;
    } else {
      userDetails.agenda_id = agendaId;
      userDetails.centro_id = centroId;
      userDetails.reco_id = reco_id;
      userDetails.company_id = this.accessData.company_id;
      userDetails.dni = this.trabajador.trabajador_nif;
      userDetails.persona_id = this.accessData.persona_id;
      userDetails.email = this.trabajador.trabajador_email;
      userDetails.telef = this.trabajador.trabajador_telef;
      userDetails.fecha_nacimiento = this.dateToString(this.trabajador.trabajador_nacimiento);
    }

    localStorage.setItem("employeeDetails", JSON.stringify(userDetails));
  }

  navigateCalender() {
    this.modalService.open(CalenderComponent, { centered: true, size: 'xl' });
  }

  navigateCancelarreserva() {
    const modalRef = this.modalService.open(CancelarReservaComponent, { centered: true });
    modalRef.componentInstance.trabajador = this.trabajador;
    modalRef.componentInstance.accessData = this.accessData;
  }

  navigateUserMessage() {
    const modalRef = this.modalService.open(CommonMessageComponent, { centered: true });
    modalRef.componentInstance.message = btoa(this.accessData.message_to_user);
    modalRef.componentInstance.fromUrl = btoa(this.router.url);
  }

  navigateRegistration() {
    this.modalService.open(EmployeeRegistrationComponent, { centered: true });
  }


}
