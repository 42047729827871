export class TrabajadoresDiplomasFilter {
    empresa_id: string;
    incl: string;
    page_number: number = 1;
    order_by: string = "NAME";
    page_size: number = 20;  
    public constructor(init?: Partial<TrabajadoresDiplomasFilter>) {
        Object.assign(this, init);
    }
}


