<div class="modal-body">
  <div class="row pl-3 pb-3">
    <div class="col-md-10 text-left pl-0">
      <h5 id="activity-modal" class="modal-title popup-title-f-s font-weight-bold">
        {{ trabajador.trabajador_txt }}
      </h5>
    </div>
    <div class="col-md-2">
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">
        <span aria-hidden="true">×</span>
      </button>
    </div>
  </div>  

  <div class="row pl-3 pb-3">
    <div class="col-md-6">
      
    </div>
    <div class="col-md-6" *ngIf="isNewDocument">
      <button type="button" class="btn btn-primary float-right" (click)="uploadDocument()">
        Subir Archivo
    </button>
    </div>
  </div>
  <nav class="add-exception-tabs">
    <ul class="nav nav-tabs" role="tablist">
      <li class="nav-item">
        <a class="nav-link active" id="nav-formaciones-tab" data-toggle="tab" href="#nav-formaciones" role="tab"
          aria-controls="nav-agregar" aria-selected="true" (click)="tabClick(1)"> FORMACIONES
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link ml-2" id="nav-otros-diplomas-tab" data-toggle="tab" href="#nav-otros-diplomas" role="tab"
          aria-controls="nav-copiar" aria-selected="false" (click)="tabClick(2)"> OTROS CERTIFICADOS Y DOCUMENTOS
        </a>
      </li>
    </ul>
  </nav>
  <div class="card card-tabs">
    <div id="nav-tabContent" class="tab-content" >
      <div id="nav-formaciones" class="tab-pane p-3 fade show active" role="tabpanel"
        aria-labelledby="nav-formaciones-tab">
        <div class="row mt-2">
          <div class="col-md-12 d-flex s-m-t-10">
            <table class="table" data-sort-order="desc">
              <caption></caption>
              <thead class="thead-light">
                <tr>
                  <th data-sortable="true">TÍTULO FORMACIÓN</th>
                  <th>FECHA</th>
                  <th> </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of combinFormacionesList">
                  <td> {{item.curso_txt}} </td>
                  <td> {{item.curso_fin | date: 'yyyy/MM/dd'}} </td>
                  <td>
                    <button type="button" class="btn-primary down-arrow" (click)="downloadFormacionDoc(item.documento_id)">
                      <span aria-hidden="true" class="fa fa-sort-desc"></span>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>

          </div>
        </div> 
      </div>
     

      <div id="nav-otros-diplomas" class="tab-pane p-3 fade show" role="tabpanel"
          aria-labelledby="nav-otros-diplomas-tab">
          <div class="row mt-2">
            <div class="col-md-12 d-flex s-m-t-10">
              <table class="table">
                <caption></caption>
                <thead class="thead-light">
                  <tr>
                    <th>DIPLOMATURA</th>
                    <th>FECHA</th>                  
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of diplomasList">
                    <td> {{item.documento_txt}} </td>
                    <td> {{item.fecha_diplomatura | date: 'yyyy/MM/dd'}} </td>
                    <td>
                      <button type="button" class="btn-primary down-arrow" (click)="downloadDiplomasDoc(item.documento_id)">
                        <span aria-hidden="true" class="fa fa-sort-desc"></span>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>

            </div>
          </div>
        </div>
    </div>

  </div>