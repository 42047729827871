import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AnyAaaaRecord } from 'dns';
import { Observable } from 'rxjs';
import { Constants } from './constants';
import { LanguageItem, LanguageRespond } from '../model/language-respond';

@Injectable({
  providedIn: 'root'
})
export class CommonApiService {
  url = "lists";

  constructor(private _httpClient: HttpClient){    
  }

  public getLanguages(filter: any): Observable<LanguageRespond> {
    return this._httpClient.post<LanguageRespond>(Constants.apiRoot + this.url + '/languages' , filter);
  }

  

}
