import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CompanyInformationTreeResponse } from '../model/company-information-tree-response';
import { Constants } from './constants';

@Injectable({
  providedIn: 'root'
})
export class CompanyInformationDocumentTreeService {

  private url = 'empresa';

  constructor(private _httpClient: HttpClient) {
  }

  public getCompanyInfoTree(): Observable<CompanyInformationTreeResponse> {
    return this._httpClient.post<CompanyInformationTreeResponse>(Constants.apiRoot + this.url + '/tree', null);
  }

  public getTechUserInfoTree(company: any): Observable<CompanyInformationTreeResponse> {
    return this._httpClient.post<CompanyInformationTreeResponse>(Constants.apiRoot + this.url + '/tree-empresa', company);
  }
}
