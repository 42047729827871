/*
  object sample:
  {
    "id_curso": "",
    "order_by": "NAME",
    "top_n": 10,
    "page": 1,
    "trabajador": ""
   }
*/
export class TrainingCertificateDetailsItemFilter {
    id_curso: string;
    order_by: string;
    top_n: number;
    page: number;
    trabajador: string;

    public constructor(init?: Partial<TrainingCertificateDetailsItemFilter>) {
        Object.assign(this, init);
    }
}
