import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-trabajadore',
  templateUrl: './trabajadore.component.html',
  styleUrls: ['./trabajadore.component.scss']
})
export class TrabajadoreComponent implements OnInit {
  // tabStatus: boolean = true;
  eventsSubject: Subject<boolean> = new Subject<boolean>();
  
  constructor() { 
  }

  ngOnInit(): void {
    this.eventsSubject.next(true);
  }

  getTrabajadore(_activos: boolean = true) { 
    this.eventsSubject.next(_activos);
  }

  


  
}
