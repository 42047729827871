export class CompanyTreeViewModel {
    id: string;
    id_branch: string;
    name: string;
    children: Array<CompanyTreeViewModel>;
    random_id: number;
    isCollapsed: boolean;

    constructor() {
        this.children = new Array<CompanyTreeViewModel>();
        this.random_id = Math.floor((Math.random() * 100000) + 1);
    }
}
