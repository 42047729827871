import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './auth/components/home/home.component';
import { SigninRedirectCallbackComponent } from './auth/components/home/signin-redirect-callback.component';
import { SignoutRedirectCallbackComponent } from './auth/components/home/signout-redirect-callback.component';
import { UnauthorizedComponent } from './core/unauthorized.component';
import { MainLayoutComponent } from './layout/main-layout/main-layout.component';
import { LoginLayoutComponent } from './layout/login-layout/login-layout.component';
import { AuthGuard } from './shared/services/auth-guard';
import { PageIndexComponent } from './modules/page-index/page-index/page-index.component';
import { HealthReportsComponent } from './modules/health-reports/health-reports/health-reports.component';
import { CompDocComponent } from './modules/comp-doc/comp-doc/comp-doc.component';
import { TrnCertComponent } from './modules/training/trn-cert/trn-cert.component';
import { TrnInfoComponent } from './modules/training/trn-info/trn-info.component';
import { PowerBiReportComponent } from './modules/power-bi-reports/power-bi-report/power-bi-report.component';
import { ProceduresComponent } from './modules/procedures/procedures/procedures/procedures.component';
import { TrabajadoreComponent } from './modules/trabajadore/trabajadore/trabajadore.component';


const routes: Routes = [
  // {
  //   path: '',
  //   component: MainLayoutComponent,
  //   canActivate: [AuthGuard]
  // },
  {
    path: '',
    component: MainLayoutComponent,
      children: [
      { path: '',  component: PageIndexComponent,  pathMatch: 'full', canActivate: [AuthGuard] },
      { path: 'pageindex', component: PageIndexComponent, canActivate: [AuthGuard] },
      { path: 'healthreports', component: HealthReportsComponent, canActivate: [AuthGuard] },
      { path: 'compdoc', component: CompDocComponent, canActivate: [AuthGuard] },
      { path: 'trncert', component: TrnCertComponent, canActivate: [AuthGuard] },
      { path: 'trninfo', component: TrnInfoComponent, canActivate: [AuthGuard] },      
      { path: 'procedures', component: ProceduresComponent, canActivate: [AuthGuard] },
      { path: 'trabajadores', component: TrabajadoreComponent, canActivate: [AuthGuard] }
    ]
  },
  { path: 'powerbi', component: PowerBiReportComponent, canActivate: [AuthGuard] },
  {
    path: '',
    component: LoginLayoutComponent,
    children: [
      { path: 'signin-callback', component: SigninRedirectCallbackComponent },
      { path: 'signout-callback', component: SignoutRedirectCallbackComponent },
      { path: 'unauthorized', component: UnauthorizedComponent },
      { path: 'signin', component: HomeComponent }      
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
