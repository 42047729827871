import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-left-panel',
  templateUrl: './left-panel.component.html',
  styleUrls: ['./left-panel.component.scss']
})
export class LeftPanelComponent implements OnInit {
  openCompany = true;

  constructor(private router: Router) {
  }

  ngOnInit(): void {
  }

  navigate(page: string) {
    this.router.navigate([page]).then();
  }
}
