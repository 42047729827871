
  <div class="modal-body">
    <div class="row text-center pl-3 pb-3">
      <h6 class="font-weight-bold">
        ¿Estás seguro de que quieres cancelar tu reserva?
      </h6>
    </div>
    <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12 d-flex mt-5 mb-3">
      <div class="col-lg-6 col-xl-6 col-md-6 col-sm-6">
        <button class="btn btn-primary mb-1 w-100" type="button" (click)="cancelAppointment()">
          SI
        </button>
      </div>
      <div class="col-lg-6 col-xl-6 col-md-6 col-sm-6">
        <button class="btn btn-primary w-100" type="button" (click)="navigateToCenters()">
          NO
        </button>
      </div>
    </div>
  </div>
