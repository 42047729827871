<div *ngIf="isLoading">
    <app-spinner></app-spinner>
</div>
<div class="salud-report">
    <div class="row header-title">
        <div class="col-md-8 col-lg-10 title-line s-w-70">
            <h5> Certificados de Aptitud</h5>
        </div>
        <div class="col-md-2">
            <a [routerLink]="['../pageindex']">Volver</a>
        </div>
    </div>
    <form [formGroup]="filterForm" name="activityForm" #f="ngForm" novalidate (ngSubmit)="btnFilter()">
        <div class="row">
            <div class="col-md-6 col-lg-3 d-flex">
                <ng-select #select (close)="select.blur()" loadingText="Cargando..." notFoundText="No hay resultados"
                    [items]="filterCenters" id="filter-center" name="filter-center" [clearable]="true"
                    [virtualScroll]="true" bindLabel="nombre_centro" bindValue="id" placeholder="Centro de Trabajo"
                    formControlName="fCenterName" (change)="selectCenter($event)">
                </ng-select>
            </div>
            <div class="col-md-6  col-lg-3 d-flex s-m-t-10">
                <ng-select #select (close)="select.blur()" loadingText="Cargando..." notFoundText="No hay resultados"
                    [items]="filterEmployees" id="filter-employee" name="filter-employee" [clearable]="true"
                    [virtualScroll]="true" bindLabel="fullName" bindValue="id" placeholder="Trabajador"
                    formControlName="fEmployeeName" (change)="selectEmployee($event)">
                </ng-select>
            </div>
        </div>
        <div class="row mt-4 d-md-flex d-lg-flex d-xl-block">
            <div class="form-group row col-md-6 col-lg-6 col-xl-12 d-md-block d-lg-block d-xl-flex">
                <label class="col-lg-2 col-md-4 col-form-label">Fecha Reconocimiento DESDE:</label>
                <div class="col-lg-2 col-md-3">
                    <input [owlDateTime]="filterRecoDesdeDtPicker" [owlDateTimeTrigger]="filterRecoDesdeDtPicker"
                        type="text" class="form-control form-control-sm date-field" placeholder="DESDE"
                        formControlName="filterRecoDesdeDt"
                        [ngClass]="{'border-danger': !filterForm.get('filterRecoDesdeDt').valid && filterForm.get('filterRecoDesdeDt').touched}">
                    <span class="text-danger"
                        *ngIf="!filterForm.get('filterRecoDesdeDt').valid && filterForm.get('filterRecoDesdeDt').touched">
                        Formato de fecha incorrecto (dd/mm/yyyy)
                    </span>
                    <owl-date-time #filterRecoDesdeDtPicker [pickerType]="'calendar'"></owl-date-time>
                </div>
                <label class="col-lg-2 col-md-4 col-form-label">Fecha Documento DESDE:</label>
                <div class="col-lg-2 col-md-3">
                    <input [owlDateTime]="filterDocDesdeDtPicker" [owlDateTimeTrigger]="filterDocDesdeDtPicker"
                        type="text" class="form-control form-control-sm date-field" placeholder="DESDE "
                        formControlName="filterDocDesdeDt"
                        [ngClass]="{'border-danger': !filterForm.get('filterDocDesdeDt').valid && filterForm.get('filterDocDesdeDt').touched}">
                    <span class="text-danger"
                        *ngIf="!filterForm.get('filterDocDesdeDt').valid && filterForm.get('filterDocDesdeDt').touched">
                        Formato de fecha incorrecto (dd/mm/yyyy)
                    </span>
                    <owl-date-time #filterDocDesdeDtPicker [pickerType]="'calendar'"></owl-date-time>
                </div>
            </div>
            <div class="form-group row col-md-6 col-lg-6 col-xl-12 d-md-block d-lg-block d-xl-flex">
                <label class="col-lg-2 col-md-3 col-form-label">Fecha Reconocimiento HASTA:</label>
                <div class="col-lg-2 col-md-3">
                    <input [owlDateTime]="filterRecoHastaDtPicker" [owlDateTimeTrigger]="filterRecoHastaDtPicker"
                        type="text" class="form-control form-control-sm date-field" placeholder="HASTA "
                        formControlName="filterRecoHastaDt"
                        [ngClass]="{'border-danger': !filterForm.get('filterRecoHastaDt').valid && filterForm.get('filterRecoHastaDt').touched}">
                    <span class="text-danger"
                        *ngIf="!filterForm.get('filterRecoHastaDt').valid && filterForm.get('filterRecoHastaDt').touched">
                        Formato de fecha incorrecto (dd/mm/yyyy)
                    </span>
                    <owl-date-time #filterRecoHastaDtPicker [pickerType]="'calendar'"></owl-date-time>
                </div>
                <label class="col-lg-2 col-md-3 col-form-label">Fecha Documento HASTA:</label>
                <div class="col-md-2">
                    <input [owlDateTime]="filterDocHastaDtPicker" [owlDateTimeTrigger]="filterDocHastaDtPicker"
                        type="text" class="form-control form-control-sm date-field" placeholder="HASTA "
                        formControlName="filterDocHastaDt"
                        [ngClass]="{'border-danger': !filterForm.get('filterDocHastaDt').valid && filterForm.get('filterDocHastaDt').touched}">
                    <span class="text-danger"
                        *ngIf="!filterForm.get('filterDocHastaDt').valid && filterForm.get('filterDocHastaDt').touched">
                        Formato de fecha incorrecto (dd/mm/yyyy)
                    </span>
                    <owl-date-time #filterDocHastaDtPicker [pickerType]="'calendar'"></owl-date-time>
                </div>
                <div class="col-md-4 md-mt-3">
                    <button type="submit" class="btn-primary s-m-t-10">Filtrar</button>
                </div>
            </div>
        </div>
    </form>
    <div>
        <table class="table">
            <caption></caption>
            <thead class="thead-light">
                <tr>
                    <th scope="col" (click)="onChangeOrder(queryTypes[0])">
                        Nombre <span class="fa" aria-hidden="true"
                        [ngClass]="{'fa-sort-desc': queryTypes[0].direction === 'DESC', 
                        'fa-sort-asc': queryTypes[0].direction === 'ASC' }"></span>
                    </th>
                    <th scope="col" (click)="onChangeOrder(queryTypes[1])">
                        Apellidos <span class="fa" aria-hidden="true"
                        [ngClass]="{'fa-sort-desc': queryTypes[1].direction === 'DESC', 
                        'fa-sort-asc': queryTypes[1].direction === 'ASC' }"></span>
                    </th>
                    <th scope="col" (click)="onChangeOrder(queryTypes[2])">
                        Centro Trabajo <span class="fa" aria-hidden="true"
                        [ngClass]="{'fa-sort-desc': queryTypes[2].direction === 'DESC', 
                        'fa-sort-asc': queryTypes[2].direction === 'ASC' }"></span>
                    </th>
                    <th scope="col" (click)="onChangeOrder(queryTypes[3])">
                        Fecha Reconocinimiento <span class="fa" aria-hidden="true"
                        [ngClass]="{'fa-sort-desc': queryTypes[3].direction === 'DESC', 
                        'fa-sort-asc': queryTypes[3].direction === 'ASC' }"></span>
                    </th>
                    <th scope="col" (click)="onChangeOrder(queryTypes[4])">
                        Fecha Documento <span class="fa" aria-hidden="true"
                        [ngClass]="{'fa-sort-desc': queryTypes[4].direction === 'DESC', 
                        'fa-sort-asc': queryTypes[4].direction === 'ASC' }"></span>
                    </th>
                    <th scope="col" class="w-5">Descargar</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let docObj of documents | paginate : paginationConfig">
                    <td>{{docObj.trab_nombre }}</td>
                    <td>{{docObj.trab_apell }}</td>
                    <td>{{docObj.cen_nombre }}</td>
                    <td>{{_commonUiService.dateStringFormat(docObj.f_reco)}}</td>
                    <td>{{_commonUiService.dateStringFormat(docObj.f_doc)}}</td>
                    <td class="text-center">
                        <button type="button" class="btn-primary down-arrow" (click)="getDocumentoFile(docObj.id)">
                            <span class="fa fa-sort-desc" aria-hidden="true"></span>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="row">
        <div class="col-md-8"></div>
        <div class="col-md-4 md-mt-3 text-right">
            <button type="button" class="btn-primary s-m-t-10" (click)="downloadAllDocumentoFiles()">Descargar Lista</button>
        </div>
    </div>

    <div class="row pagination-div">
        <div class="col-md-8 d-flex">
            <pagination-controls previousLabel="Anterior" nextLabel="Siguiente" (pageChange)="pageChanged($event)"
                (pageBoundsCorrection)="pageChanged($event)">
            </pagination-controls>
            <span class="float-left doc-find">Total de {{recCount}} documentos encontrados</span>
        </div>
        <div class="col-md-4">
        </div>
    </div>
</div>