import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AutoReversaService } from 'src/app/core/auto-reversa.service';
import { CentersListComponent } from '../centers-list/centers-list.component';
import { CommonUiService } from 'src/app/shared/services/common-ui.service';
import { CommonMessageComponent } from 'src/app/shared/components/common-message/common-message.component';
import { TrabajadoreDetItem } from 'src/app/model/trabajadores-respond';

@Component({
  selector: 'app-cancelar-reserva',
  templateUrl: './cancelar-reserva.component.html',
  styleUrls: ['./cancelar-reserva.component.scss']
})
export class CancelarReservaComponent implements OnInit {
  @Input() trabajador: TrabajadoreDetItem;
  @Input() accessData: any;

  constructor(private router: Router, private autoreversaService : AutoReversaService,
    public modal: NgbActiveModal,
    private _commonUiService: CommonUiService,
    private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  cancelAppointment() {
    const userDetails = JSON.parse(localStorage.getItem("employeeDetails") || 'null');
    const postData = { 
      "reco_id": userDetails.reco_id, 
      "company_id": userDetails.company_id, 
      "dni": userDetails.dni, 
      "fecha_nacimiento": userDetails.fecha_nacimiento 
    };

    this.autoreversaService.deleteAppointment(postData).subscribe(res => {
      if (res?.webError == null && res?.data?.messageToUser != null) {
        let messageToUser = res.data.messageToUser || ' ';
        this.navigateToUserMessage(messageToUser);
      } else {
        this._commonUiService.showFailToast("Error ", res.webError.messageToUser);
      }
    });
  }

  navigateToUserMessage(message: string) {
    this.modal.dismiss();
    const modalRef = this.modalService.open(CommonMessageComponent, { centered: true });
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.from = btoa(this.router.url);
  }

  navigateToCenters() {
    this.modal.dismiss();
    const modalRef = this.modalService.open(CentersListComponent, { centered: true, size: 'xl' });
    modalRef.componentInstance.trabajador = this.trabajador;
    modalRef.componentInstance.accessData = this.accessData;
  }

}
