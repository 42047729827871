import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Constants } from '../constants';
import { ProceduresDownloadDocRequest, ProceduresDownloadDocResponse, ProceduresGenerateItemResponse, ProceduresListResponse, ProceduresRegenerateDocRequest } from '../model/procedures-list-response';
import { ApiService } from '../shared/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class ProceduresService {

  constructor(private _apiService: ApiService) { }

  getEmpresaDPsList(companyData: any): Observable<ProceduresListResponse> {
    const url = "empresa/procedimientos";
    return this._apiService.post(Constants.apiRoot + url, companyData);
  }

  GenDPDocument(body: ProceduresRegenerateDocRequest): Observable<ProceduresGenerateItemResponse> {
    const url = "empresa/procedimiento/update";
    return this._apiService.post(Constants.apiRoot + url, body);
  }

  downloadDocumnetById(body: ProceduresDownloadDocRequest): Observable<ProceduresDownloadDocResponse> {
    const url = "empresa/procedimiento/documento"
    return this._apiService.post(Constants.apiRoot + url, body);
  }


}
