<div *ngIf="isLoading">
  <app-spinner></app-spinner>
</div>
<header>
  <nav class="navbar navbar-expand-md navbar-dark fixed-top">
    <div class="logo mr-15">
      <img alt="logo" class="logo-icon" src="assets\images\geseme-logo-white.png">
    </div>
  </nav>
  <div class="row user-log-row">
    <div class="col-sm-6 col-md-6 col-lg-3 s-w-50 mt-1">
      <form [formGroup]="empresaForm">
        <div *ngIf="isEmpresaSelect" class="mt-1 mr-5">
          <label>
            {{title}}
          </label> &nbsp;
          <button *ngIf="!isCompanyUser" type="button" class="close" aria-label="Close" (click)="onCloseEmprasa()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div *ngIf="!isEmpresaSelect" [attr.disabled]="!isLoading">
          <ng-select #select [clearAllText]="'limpiar todo'" [virtualScroll]="true" (close)="select.blur()"
            loadingText="Cargando..." notFoundText="No hay resultados" id="empresa" name="empresa" bindLabel="label"
            bindValue="value" placeholder="Seleccione" [items]="empresaList" formControlName="empresa"
            (keyup)="onKeyUpEmprasa($event)" (scrollToEnd)="onScrollEmprasa()" (change)="selectEmprasa($event)"
            (clear)="onCloseEmprasa()">
          </ng-select>
        </div>
      </form>
    </div>
    <div class="col-sm-6 col-md-6 col-lg-7 s-w-50">
    </div>
    <div class="col-sm-6 col-md-6 col-lg-2 s-w-50">
      <a (click)="logout()">Cerrar Sesión</a>
    </div>
  </div>
</header>