import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Constants } from 'src/app/constants/constant';
import { PDFDocument } from 'pdf-lib';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { CentrosytrabajadoresListService } from 'src/app/core/centrosytrabajadores-list.service';
import { DocumentoFileService } from 'src/app/core/documento-file.service';
import { DropdownService } from 'src/app/core/dropdown.service';
import { FileUploadRequest } from 'src/app/model/file-upload-request';
import { ConfirmationPopupComponent } from 'src/app/modules/popups/confirmation-popup/confirmation-popup.component';
import { CommonUiService } from 'src/app/shared/services/common-ui.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-document-upload',
  templateUrl: './document-upload.component.html',
  styleUrls: ['./document-upload.component.scss']
})
export class DocumentUploadComponent implements OnInit {  
  public fileDocument: PDFDocument;
  fileDoc: File;
  fileName: string = "";  
  public: boolean = false;
  public fileUploadRequest= new FileUploadRequest();
  isUpdate: boolean = false;
  public isLoading = false;
  archivoList: any;
  tipoSearch: string = null;

  @Input() public fileDocumentDetails: any;
  @Input() public id_ubicacion: string;
  @Input() public trabajador_id: string;
  @Input() public mode: string;
  @Output() isDelete: EventEmitter<any> = new EventEmitter();
  @Output() isSaved: EventEmitter<any> = new EventEmitter();
  isEliminar: boolean = true;
  error: string;
  private tipoSubject: Subject<string> = new Subject();

  tiposRequest = {
    incl: null,
    page_number: 1,
    page_size: 20
  }

  documentUploadForm = new FormGroup({
    id_archivo: new FormControl(),
    id_trabajador: new FormControl(),
    id_ubicacion: new FormControl(),
    nombre: new FormControl('', [Validators.required]),
    description: new FormControl(),
    fecha: new FormControl(null, [Validators.required]),
    archivo: new FormControl(null , [Validators.required]),
  });
  constructor(public modal: NgbActiveModal,
    public _commonUiService: CommonUiService,
    private dropdownService: DropdownService,
    public _centroTrabajadoresService: CentrosytrabajadoresListService,
    private modalService: NgbModal,
    public _documentFileService: DocumentoFileService) { }

  ngOnInit() {
    if (this.fileDocumentDetails != null) {
      this.isEliminar = this.mode == 'doc' ? true : false;
      let fechadate = this.stringToDate(this.fileDocumentDetails.fecha);
      this.documentUploadForm.controls.id_archivo.setValue(this.fileDocumentDetails.id_archivo);
      this.documentUploadForm.controls.nombre.setValue(this.fileDocumentDetails.filename);
      this.documentUploadForm.controls.fecha.setValue(new Date(fechadate));
      this.documentUploadForm.controls.description.setValue(this.fileDocumentDetails.descripcion);
      this.documentUploadForm.controls.id_ubicacion.setValue(this.fileDocumentDetails.id_ubicacion);
      this.documentUploadForm.controls.id_trabajador.setValue(this.fileDocumentDetails.trabajador_id);
      this.documentUploadForm.controls.archivo.setValue(this.fileDocumentDetails.id_tipo);
      this.isUpdate = true;
      this.documentUploadForm.controls.nombre.disable();
    } else {
      this.documentUploadForm.controls.id_ubicacion.setValue(this.id_ubicacion);
      this.documentUploadForm.controls.id_trabajador.setValue(this.trabajador_id);
      this.isEliminar = false;
      this.isUpdate = false;
    }
    this.getAllArchivoList();
    this.tipoSubject.pipe(debounceTime(Constants.DEBOUNCE_TIME), distinctUntilChanged())
    .subscribe((name) => this.loadTopMatchingTipo(name));

  }
  
  loadTopMatchingTipo(name: any) {    
    if (name.length >= 4) {  
      this.getArchivoList(name);
    } else {
      if (name.length == 0) { 
        this.getArchivoList();
      }
    }
  }
  
  onScrollTipo() {
    this.getArchivoList(this.tipoSearch,true);
  }

  onCloseTipo() {
    this.tipoSearch = null;
    this.getArchivoList();
  }

  onKeyUpTipo(event) {
    this.tipoSearch = event?.target?.value;     
    this.tipoSubject.next(event?.target?.value); 
  }


  getArchivoList(incl: string= null, isScroll = false) {
      this.isLoading = true; 
      this.tiposRequest.incl = incl;  
      this.tiposRequest.page_number = isScroll ? (this.tiposRequest.page_number + 1) : 1;
  
      this._centroTrabajadoresService.getDocumentType(this.tiposRequest).subscribe(res => {
        if (res.webError != null) {
          this.error = res.webError.messageToUser;
          this.isLoading = false;
        } else {
          this.archivoList = isScroll ? this.archivoList.concat(this.dropdownService.WrappedToDropDownList(res.data, 'tipo_txt', 'tipo_id'))
          : this.dropdownService.WrappedToDropDownList(res.data, 'tipo_txt', 'tipo_id');  
          this.isLoading = false;
        }
      });
  }

  getAllArchivoList(incl: string = null, isScroll = false) {
    if (!isScroll) {
      this.resetArchivoList();
    }
  
    this.isLoading = true;
    this.tiposRequest.incl = incl;
  
    this._centroTrabajadoresService.getDocumentType(this.tiposRequest).subscribe({
      next: (res) => this.handleResponse(res, incl),
      error: (err) => this.handleError(err),
    });
  }
  
  private resetArchivoList() {
    this.tiposRequest.page_number = 1;
    this.archivoList = [];
  }
  
  private handleResponse(res: any, incl: string) {
    if (res.webError != null) {
      this.error = res.webError.messageToUser;
      this.isLoading = false;
      return;
    }
  
    this.archivoList = [
      ...this.archivoList,
      ...this.dropdownService.WrappedToDropDownList(res.data, 'tipo_txt', 'tipo_id')
    ];
  
    if (res.data.length > 0) {
      this.tiposRequest.page_number++;
      this.getAllArchivoList(incl, true);
    } else {
      this.isLoading = false;
    }
  }
  
  private handleError(err: any) {
    // Handle the error accordingly
    console.error('An error occurred:', err);
    this.isLoading = false;
  }
  

  stringToDate(dateString: string) {
    return dateString.substring(0,4) + '/' + dateString.substring(4,6) + '/' + dateString.substring(6,9);
  }

  public async uploadPdf(files: FileList): Promise<void> {
    if (files.length === 1) {
      this.fileDoc = files[0];
      this.fileName = this.fileDoc.name;
    } else {
      this._commonUiService.showWarningMessage('seleccione un archivo PDF válido para enviar');
    }
  }

  addDocument() {
    if(this.fileDoc) {
      this.isLoading = true;
      let fecha_desde= new Date(this.documentUploadForm.controls.fecha.value); 
      const formData = new FormData();
      formData.append('file', this.fileDoc);
      
      this.fileUploadRequest.doc = {
        id_ubicacion: this.documentUploadForm.controls.id_ubicacion.value,
        id_trabajador: this.documentUploadForm.controls.id_trabajador.value,
        descripcion: this.documentUploadForm.controls.description.value,
        filename: this.documentUploadForm.controls.nombre.value,
        id_tipo: this.documentUploadForm.controls.archivo.value,
        fecha: this.dateToString(fecha_desde),
      };      

      formData.append('doc', JSON.stringify(this.fileUploadRequest.doc));

      this._documentFileService.addDocument(formData).subscribe(res => {
        if(res.webError == null) {
          this.isLoading = false;
          this._commonUiService.showSuccessToast("Subido con éxito.");
          this.clearDocument();
          this.isSaved.emit(true);
          this.modal.dismiss();
        } else {
          this.isLoading = false;
          this._commonUiService.showFailToast("",res.webError?.messageToUser);
        } 
      });
    }  
  }

  updateDocument() {
    this.isLoading = true;
    let fecha_desde= new Date(this.documentUploadForm.controls.fecha.value); 

    let fileUploadRequest = {
      doc_id: this.documentUploadForm.controls.id_archivo.value,
      id_tipo: this.documentUploadForm.controls.archivo.value,
      descripcion: this.documentUploadForm.controls.description.value,
      fecha: this.dateToString(fecha_desde),
    };

    this._documentFileService.updateDocument(fileUploadRequest).subscribe(res => {
      if(res.webError == null) {
        this.isLoading = false;
        this._commonUiService.showSuccessToast("Subido con éxito.");        
        this.clearDocument();
        this.isSaved.emit(true);
        this.modal.dismiss();
      } else {
        this.isLoading = false;
        this._commonUiService.showFailToast("",res.webError?.messageToUser);
      } 
    });
  }  

  generateDocument() {
    if(this.isUpdate) {
      this.updateDocument();
    } else {
      this.addDocument();
    }
  }

  clearDocument() {
    this.documentUploadForm.reset();
    this.fileName = "";
  }

  dateToString(fecha_desde) {
    const year = fecha_desde.getFullYear();
    const month = fecha_desde.getMonth();
    const day = fecha_desde.getDate();
    return year + ('0' + (month+1)).slice(-2)  + ('0' + day).slice(-2);
  }

  onChangePublic(event) {
    this.public = event.target.checked;
  }

  deleteDocument() {
    const modalRef = this.modalService.open(ConfirmationPopupComponent, { centered: true });
    modalRef.componentInstance.confirmationMessage = '¿Está seguro de que desea eliminar este archivo?';
    modalRef.componentInstance.messageBody = "Si lo elimina ya no podrá ser recuperado";
    modalRef.result.then(close => {
      if (close) {
        this.deleteDocumentAPICall();
      }
    });
  }

  deleteDocumentAPICall() {
    this.isLoading = true;
    let deletedocdata =  {
        doc_id: this.documentUploadForm.controls.id_archivo.value
    }

    this._documentFileService.deleteDocument(deletedocdata).subscribe(res => {
      if(res.webError == null) {
        this.isLoading = false;
        this._commonUiService.showSuccessToast("borrado exitosamente !!!");
        this.isDelete.emit(true);
        this.modal.dismiss();
      } else {
        this.isLoading = false;
        this._commonUiService.showFailToast("",res.webError?.messageToUser);
      }
    });
  }

}
