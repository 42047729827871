import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CompanyInformationDocumentFileService } from 'src/app/core/company-information-document-file.service';
import { CompanyInformationDocumentListService } from 'src/app/core/company-information-document-list.service';
import { CompanyInformationDocumentTreeService } from 'src/app/core/company-information-document-tree.service';
import { Utils } from 'src/app/core/utils';
import { CompanyInformationDocument } from 'src/app/model/company-inforation-document';
import { CompanyInformationDocumentListResponse } from 'src/app/model/company-information-document-list-response';
import { CompanyInforTreeCompanyNode } from 'src/app/model/company-information-tree-response-details';
import { CompanyTreeViewModel } from 'src/app/model/company-tree-view-model';
import { DocumentoFileQuery } from 'src/app/model/documento-file-query';
import { DocumentoFileResponse } from 'src/app/model/documento-file-response';
import { DocumentoOrderQuery } from 'src/app/model/documento-order-query';
import { NgxPaginationConfig } from 'src/app/model/ngx-pagination-config';
import { CommonUiService } from 'src/app/shared/services/common-ui.service';
import { TitleService } from 'src/app/shared/services/title.service';
import { DocumentUploadComponent } from './document-upload/document-upload.component';
import { CentroItem } from 'src/app/model/centro-item';
import { TrabajadorItem } from 'src/app/model/trabajador-item';
import { CentrosirabajadorItem } from 'src/app/model/centrosirabajador-item';
import { CentrosytrabajadoresListService } from 'src/app/core/centrosytrabajadores-list.service';
import { SelectOptions } from 'src/app/model/select-options.model';
import { DropdownService } from 'src/app/core/dropdown.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Constants } from 'src/app/constants/constant';
import { AdvanceFilterRequest } from 'src/app/model/advance-filter-request';
import { DocumentoFileService } from 'src/app/core/documento-file.service';

@Component({
  selector: 'app-comp-doc',
  templateUrl: './comp-doc.component.html',
  styleUrls: ['./comp-doc.component.scss']
})
export class CompDocComponent implements OnInit {
  public isLoading = false;
  error: string;
  recCount = 0;
  recPP = 25;
  pageNum = 1;
  pageCnt = 1;
  orderBy: string;
  company: any;
  branchId: string;
  levelValue: string;

  paginationConfig: NgxPaginationConfig;
  queryTypes: DocumentoOrderQuery[];
  responseList: CompanyInformationDocumentListResponse;
  documents: CompanyInformationDocument[];
  companyTree = new CompanyInforTreeCompanyNode();
  responseFile: DocumentoFileResponse;
  treeViewModel = new Array<CompanyTreeViewModel>();
  filterCenters: CentroItem[];
  filterEmployees: SelectOptions[] = [];
  allEmployees: TrabajadorItem[];
  centrerEmployees: CentrosirabajadorItem[];
  trabajadoreSearch: string = null;
  tipoSearch: string = null;
  documentTypeList: any;
  trabajador_id: string = null;
  tipodoc_id: string = null;
  tabClicked: number = 1;
  public advanceFilterItem: AdvanceFilterRequest = new AdvanceFilterRequest();

  filterCenter: CentroItem;
  filterEmployee: TrabajadorItem;
  private trabajadoreSubject: Subject<string> = new Subject();
  private tipoSubject: Subject<string> = new Subject();

  workCentersRequestData = {
    empresa_id: null,
    incl: null
  };

  tiposRequest = {
    incl: null,
    page_number: 1,
    page_size: 20
  }

  trbajadoreRequest = {
    empresa_id: null,
    centro_id: null,
    incl: null,
    page_number: 1,
    page_size: 20
  };

 public directriesForm = new FormGroup({
    fDocumentName: new FormControl(null),
    filterDesdeDate: new FormControl(null),
    filterHastaDate: new FormControl(null),
    fCompanyLevel: new FormControl(null),
    fshowDocument: new FormControl(false)
  });

  public AdSearchForm = new FormGroup({
    aDocumentName: new FormControl(null),
    aFilterDesdeDate: new FormControl(null),
    aFilterHastaDate: new FormControl(null),
    aShowDocument: new FormControl(false),
    aCenterName: new FormControl(null),
    aEmployeeName: new FormControl(null),
    aDocumentType: new FormControl(null),
  });
  categoriesTab: boolean;
  categoryDetailsList: any;
  hoveredIndex: number = -1;
  showTooltip: number = -1;
  subCategoryDetailsList: any;
  showSubTooltip: number = -1;
  hoveredSubIndex: number = -1;
  selectedIconId: any;

  constructor(
    private _titleService: TitleService,
    public _commonUiService: CommonUiService,
    private modalService: NgbModal,
    private dropdownService: DropdownService,
    public _centroTrabajadoresService: CentrosytrabajadoresListService,
    private _companyInformationDocumentTreeService: CompanyInformationDocumentTreeService,
    private _companyInformationDocumentListService: CompanyInformationDocumentListService,
    private _companyInformationDocumentFileService: CompanyInformationDocumentFileService,
    public _documentFileService: DocumentoFileService) { 
      this.company = JSON.parse(localStorage.getItem('company'));
    }

  ngOnInit(): void {
    this.initilizeFormValue();
    this.initilizePageValue();
    this.queryTypes = this._companyInformationDocumentListService.order_query_list;
    this.orderBy = 'NAME';
    this.queryTypes[0].direction = 'ASC';
    this.getPageTree();
    this.getWorkCenters();
    this.getTrabajadoresAllowed();
    this.getDocumentType();
    this.trabajadoreSubject.pipe(debounceTime(Constants.DEBOUNCE_TIME), distinctUntilChanged())
      .subscribe((name) => this.loadTopMatchingTrabajadore(name));

    this.tipoSubject.pipe(debounceTime(Constants.DEBOUNCE_TIME), distinctUntilChanged())
      .subscribe((name) => this.loadTopMatchingTipo(name));
  }

  initilizeFormValue() {
    this.advanceFilterItem.docs_validos = false;
    this.advanceFilterItem.centro_id = null;
    this.advanceFilterItem.trabajador_id = null;
    this.advanceFilterItem.tipo_doc_id = null;
  }

  initilizePageValue() {    
    this.paginationConfig = new NgxPaginationConfig();
    this.paginationConfig.itemsPerPage = 15;
    this.paginationConfig.totalItems = 0;
  }

  getPageTree(): void {
    this.error = '';
    this.isLoading = true;
    if(this.company.userType == 'tech') {
      this.gettechUserInfotree();
    } else {
      this.getCompanyInfoTree();
    }    
  }  

  gettechUserInfotree() {
    let companyData = {
      empresa_id : this.company.empresa_id
    }

    this._companyInformationDocumentTreeService.getTechUserInfoTree(companyData).subscribe(response => {
      if (response.webError != null) {
        this.error = response.webError.messageToUser;
        this._commonUiService.showWarningMessage(this.error);
      } else {
        this.companyTree = response.data;
        this.mapTreeToModel();
        const title = this.companyTree.name_empresa;
        this._titleService.setTitle(title);
        this.directriesForm.get('fCompanyLevel').patchValue(this.companyTree.id_empresa);
        this.setSelectedBranchId({branchId: this.companyTree.id_branch ,value: this.companyTree.id_empresa});
      }
      this.isLoading = false;
    }, error => {
      this.isLoading = false;
      this.error = Utils.formatError(error);
    });
  }

  getCompanyInfoTree() {
    this._companyInformationDocumentTreeService.getCompanyInfoTree().subscribe(response => {
      if (response.webError != null) {
        this.error = response.webError.messageToUser;
        this._commonUiService.showWarningMessage(this.error);
      } else {
        this.companyTree = response.data;
        this.mapTreeToModel();
        const title = this.companyTree.name_empresa;
        this._titleService.setTitle(title);
        this.directriesForm.get('fCompanyLevel').patchValue(this.companyTree.id_empresa);
        this.setSelectedBranchId({branchId: this.companyTree.id_branch ,value: this.companyTree.id_empresa});
      }
      this.isLoading = false;
    }, error => {
      this.isLoading = false;
      this.error = Utils.formatError(error);
    });
  }

  btnFilter() {
    this.pageNum = 1;
    this.paginationConfig.currentPage = this.pageNum;
    this.applyFilter();
  }

  applyFilter() {
    this.advanceFilterItem.top_n = this.recPP;
    this.advanceFilterItem.page = this.pageNum;
    this.advanceFilterItem.order_by = this.orderBy;
    this.advanceFilterItem.after_id = this.levelValue;

    if (this.tabClicked == 1 && this.directriesForm.valid) {
      const filterDesdeDate = this.directriesForm.get('filterDesdeDate').value;
      const filterHastaDate = this.directriesForm.get('filterHastaDate').value;

      this.advanceFilterItem.id_branch = this.branchId;
      this.advanceFilterItem.nameLike = this.directriesForm.get('fDocumentName').value;
      this.advanceFilterItem.dateFrom = filterDesdeDate ? this._commonUiService.dateToString(filterDesdeDate) : null;
      this.advanceFilterItem.dateTo = filterHastaDate ? this._commonUiService.dateToString(filterHastaDate) : null;
      this.getCompanyInfoDocumentList();

    } else if (this.tabClicked == 2 && this.AdSearchForm.valid) {
      const filterDesdeDate = this.AdSearchForm.get('aFilterDesdeDate').value;
      const filterHastaDate = this.AdSearchForm.get('aFilterHastaDate').value;

      this.advanceFilterItem.after_id = this.companyTree.id_empresa;
      this.advanceFilterItem.id_branch = null;
      this.advanceFilterItem.nameLike = this.AdSearchForm.get('aDocumentName').value;
      this.advanceFilterItem.dateFrom = filterDesdeDate ? this._commonUiService.dateToString(filterDesdeDate) : null;
      this.advanceFilterItem.dateTo = filterHastaDate ? this._commonUiService.dateToString(filterHastaDate) : null;

      this.getCompanyInfoDocumentList();
    } else {
      this.error = 'Valores de filtro no válidos';
      this._commonUiService.showWarningMessage(this.error);
    }
  }

  getCompanyInfoDocumentList(): void {
    this.error = '';    // erase previous errors
    this.isLoading = true;
    this._companyInformationDocumentListService.getCompanyInfoDocumentList(this.advanceFilterItem).subscribe(response => {
      this.responseList = response;

      if (this.responseList) {
        if (this.responseList.webError) {
          this.error = this.responseList.webError.messageToUser;
          this._commonUiService.showWarningMessage(this.error);
        } else {
          this.documents = response.documents;
          this.recCount = response.documents.length;
          this.paginationConfig.totalItems = this.recCount;
        }
      }
      this.isLoading = false;
    }, error => {
      this.error = Utils.formatError(error);
      this._commonUiService.showWarningMessage(this.error);
      this.isLoading = false;
    });
  }

  setSelectedBranchId(data: any): void {
    this.branchId = data.branchId;
    this.levelValue = data.value;
    this.btnFilter();
  }

  onChangeOrder(orderByElement: DocumentoOrderQuery) {
    const direction = orderByElement.direction === 'ASC' ? 'DESC' : 'ASC';
    this.queryTypes.forEach((e, i) => {
      e.direction = '';
    });
    this.queryTypes.filter(e => e.id === orderByElement.id)[0].direction = direction;
    this.orderBy = direction === 'ASC' ? orderByElement.id : orderByElement.id + ' DESC';
    this.applyFilter();
  }

  getDocumentoFile(id_documento: string): void {
    let docFile: DocumentoFileQuery;
    docFile = { id_documento };
    this.error = '';    // erase previous errors
    this._companyInformationDocumentFileService.getCompanyInformationDocument(docFile).subscribe(response => {
      this.responseFile = response;
      if (this.responseFile != null) {
        if (this.responseFile.webError != null) {
          this.error = this.responseFile.webError.messageToUser;
          this._commonUiService.showWarningMessage(this.error);
        }
        else {
          const linkSource = 'data:application/pdf;base64,' + this.responseFile.data.fichero + '\n';
          const downloadLink = document.createElement('a');
          const fileName = this.responseFile.data.nombre;
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        }
      }
    }, error => this.error = Utils.formatError(error));
  }

  public pendingPageChanged(pageNo: number): void {
    this.paginationConfig.currentPage = pageNo;
  }

  private mapTreeToModel(): void {
    const company = new CompanyTreeViewModel();
    company.id = this.companyTree.id_empresa;
    company.id_branch = this.companyTree.id_branch;
    company.name = this.companyTree.name_empresa;

    if (this.companyTree.centers) {
      this.companyTree.centers.forEach((cValue, _cIndex) => {
        const center = new CompanyTreeViewModel();
        center.id = cValue.id_center;
        center.id_branch = cValue.id_branch;        
        center.name = cValue.name_center;
        center.isCollapsed = true;

        if (cValue.places) {
          cValue.places.forEach((pValue, _pIndex) => {
            const place = new CompanyTreeViewModel();
            place.id = pValue.id_place;
            place.id_branch = pValue.id_branch;
            place.name = pValue.name_place;
            place.isCollapsed = true;

            if (pValue.workplaces) {
              pValue.workplaces.forEach((wpValue, _wpIndex) => {
                const workplace = new CompanyTreeViewModel();
                workplace.id = wpValue.id_workplace;
                workplace.id_branch = wpValue.id_branch;
                workplace.name = wpValue.name_workplace;
                workplace.isCollapsed = false;

                if (wpValue.materialAgents) {
                  wpValue.materialAgents.forEach((aValue, _aIndex) => {
                    const agent = new CompanyTreeViewModel();
                    agent.id = aValue.id_agent;
                    agent.id_branch = aValue.id_branch;
                    agent.name = aValue.name_agent;
                    agent.isCollapsed = false;
                    workplace.children.push(agent);
                  });
                }
                place.children.push(workplace);
              });
            }
            center.children.push(place);
          });
        }
        company.children.push(center);
      });
    }
    this.treeViewModel.push(company);
  }

  uploadDocument(fileDocRequest: any = null) {
    const modalRef = this.modalService.open(DocumentUploadComponent, { centered: true, size: 'md' });
    modalRef.componentInstance.fileDocumentDetails = fileDocRequest;
    modalRef.componentInstance.id_ubicacion = this.levelValue; 
    modalRef.componentInstance.id_trabajador = this.advanceFilterItem.trabajador_id;
    modalRef.componentInstance.mode = "doc";
    modalRef.componentInstance.isSaved.subscribe((res) => {
      if (res == true) {
        this.getCompanyInfoDocumentList();
      }
    });
    modalRef.componentInstance.isDelete.subscribe((res) => {
      if (res == true) {
        this.getCompanyInfoDocumentList();
      }
    });    
  }

  editDocumentFile(docObj: CompanyInformationDocument) {
    if (this.levelValue == null) {
      this.levelValue = this.companyTree.id_empresa
    }
    if (this.advanceFilterItem.trabajador_id == null && this.advanceFilterItem.centro_id != null) {      
      this.levelValue = this.advanceFilterItem.centro_id;
    }

    let fileDocRequest = {
      id_archivo: docObj.id_archivo,
      id_ubicacion: this.levelValue,
      descripcion: docObj.descr_archivo,      
      publico: false,
      id_trabajador: this.advanceFilterItem.trabajador_id,
      filename: docObj.name_archivo,
      fecha: docObj.date_archivo,
      id_tipo: docObj.id_tipo,
    }

    this.uploadDocument(fileDocRequest);
  }

  newDocumentFile() {
    if (this.levelValue == null) {
      this.levelValue = this.companyTree.id_empresa
    }
    if (this.advanceFilterItem.trabajador_id == null && this.advanceFilterItem.centro_id != null) {      
      this.levelValue = this.advanceFilterItem.centro_id;
    }
    this.uploadDocument(null);
  }

  onFilterDocument() {
    this.applyFilter();
  }

  loadTopMatchingTrabajadore(name: any) {    
    if (name.length >= 4) {  
      this.getTrabajadoresAllowed(name);
    } else {
      if (name.length == 0) { 
        this.getTrabajadoresAllowed();
      }
    }
  }

  loadTopMatchingTipo(name: any) {    
    if (name.length >= 4) {  
      this.getDocumentType(name);
    } else {
      if (name.length == 0) { 
        this.getDocumentType();
      }
    }
  }

  getWorkCenters(): void {
    this.workCentersRequestData.empresa_id = this.company.empresa_id;
    this._centroTrabajadoresService.getWorkCenterList(this.workCentersRequestData).subscribe(res => {
      if (res.webError != null) {
        this.error = res.webError.messageToUser;
      } else {
        this.filterCenters = res.data;
      }
    });
  }

  getTrabajadoresAllowed(incl: string= null, isScroll = false) { 
    this.isLoading = true;
    this.trbajadoreRequest.empresa_id = this.company.empresa_id;    
    this.trbajadoreRequest.incl = incl;  
    this.trbajadoreRequest.centro_id = this.advanceFilterItem.centro_id;
    this.trbajadoreRequest.page_number = isScroll ? (this.trbajadoreRequest.page_number + 1) : 1;

    this._centroTrabajadoresService.getTrabajadoresAllowed(this.trbajadoreRequest).subscribe(res => {
      if (res.webError != null) {
        this.error = res.webError.messageToUser;
        this.isLoading = false;
      } else {
          this.filterEmployees = isScroll ? this.filterEmployees.concat(this.dropdownService.WrappedToDropDownList(res.data, 'trabajador_txt', 'trabajador_id'))
          : this.dropdownService.WrappedToDropDownList(res.data, 'trabajador_txt', 'trabajador_id');
          this.isLoading = false;        
      }
    });
  }

  selectCenter(selectedOption: any) {
    if (selectedOption != undefined) {
      this.filterEmployees = [];
      this.AdSearchForm.controls.aEmployeeName.setValue(null);
      this.advanceFilterItem.centro_id = selectedOption.id_centro;     
      this.getTrabajadoresAllowed();
    }    
  }

  onCloseCentro() {
    this.filterEmployees = [];
    this.AdSearchForm.controls.aEmployeeName.setValue(null);
    this.advanceFilterItem.centro_id = null;
    this.getTrabajadoresAllowed();
  }

  selectEmployee(selectedOption: any) {
    if (selectedOption != undefined) {
    this.filterEmployee = selectedOption;
    }
  }

  onKeyUpTrabajadore(event) {
    this.trabajadoreSearch = event?.target?.value;     
    this.trabajadoreSubject.next(event?.target?.value); 
  }

  onScrollTrabajadore() { 
    this.getTrabajadoresAllowed(this.trabajadoreSearch,true);
  }

  onCloseTrabajadore() {
    this.advanceFilterItem.trabajador_id = null;
    this.trabajadoreSearch = null;
    this.getTrabajadoresAllowed();
    this.AdSearchForm.controls.aCenterName.enable();
  }

  selectTrabajadore(event: any) {
    if(event != undefined) {
      this.advanceFilterItem.trabajador_id = event.value;
      this.AdSearchForm.controls.aCenterName.disable();
    }    
  }

  getDocumentType(incl: string= null, isScroll = false) { 
    this.isLoading = true; 
    this.tiposRequest.incl = incl;  
    this.tiposRequest.page_number = isScroll ? (this.tiposRequest.page_number + 1) : 1;

    this._centroTrabajadoresService.getDocumentType(this.tiposRequest).subscribe(res => {
      if (res.webError != null) {
        this.error = res.webError.messageToUser;
        this.isLoading = false;
      } else {
        this.documentTypeList = isScroll ? this.documentTypeList.concat(this.dropdownService.WrappedToDropDownList(res.data, 'tipo_txt', 'tipo_id'))
        : this.dropdownService.WrappedToDropDownList(res.data, 'tipo_txt', 'tipo_id');  
        this.isLoading = false;
      }
    });
  }

  onKeyUpTipo(event) {
    this.tipoSearch = event?.target?.value;     
    this.tipoSubject.next(event?.target?.value); 
  }

  onScrollTipo() {
    this.getDocumentType(this.tipoSearch,true);
  }

  onCloseTipo() {
    this.advanceFilterItem.tipo_doc_id = null;
    this.tipoSearch = null;
    this.getDocumentType();
  }

  selectTipo(event) {
    if (event != undefined) {
      this.advanceFilterItem.tipo_doc_id = event.value;
    }
  }

  onAdvanceCheckChange(event) {
    this.advanceFilterItem.docs_validos = event.target?.checked;
  }

  onFilterCheckChange(event) {
    this.advanceFilterItem.docs_validos = event.target?.checked;
  }

 
  selectTab(event) {
    this.tabClicked = event;
    this.categoriesTab = false;
    this.selectedIconId = '';
    this.subCategoryDetailsList = [];
    this.clearForms();
    this.advanceFilterItem.docs_validos = false;
    if (event == 1) {
      this.advanceFilterItem.centro_id = null;
      this.advanceFilterItem.trabajador_id = null;
      this.advanceFilterItem.tipo_doc_id = null;            
    } 
    this.applyFilter();
  }

  clearForms() {
    this.AdSearchForm.reset();
    this.directriesForm.reset();    
  }

  // select category tab
  selectCategoriesTab(){
    this._documentFileService.getCategoryFolderList().subscribe((items) => {
      this.categoriesTab = true;
      this.categoryDetailsList = items.data;
    });
  }

  //show tooltip
  toggleTooltip(index: number, stateVar: 'showTooltip' | 'showSubTooltip'): void {
    if (this[stateVar] === index) {
      this[stateVar] = -1; // Close if already open
    } else {
      this[stateVar] = index; // Open the clicked tooltip
    }
  }
  
// close tooltip
  closeTooltip(): void {
    this.showTooltip = -1; // Close the tooltip
    this.showSubTooltip = -1;
  }

  // get sub category for respective xategory
  selectcategoryIcon(categoryId: any){
    this.selectedIconId = categoryId;
    this._documentFileService.getSubCategoryFolderList(categoryId).subscribe((items) =>{
      this.subCategoryDetailsList = items.data;
    })

  }
}
