import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DocumentoOrderQuery } from '../model/documento-order-query';
import { TrabajadorItem } from '../model/trabajador-item';
import { TrainingCertificateItem } from '../model/training-certificate-item';
import { TrainingCertificateDetailsItemFilter } from '../model/training-certificate-item-details-filter';
import { TrainingCertificateItemDetailsResponse } from '../model/training-certificate-item-details-response';
import { Constants } from './constants';

@Injectable({
  providedIn: 'root'
})
export class TrainingCertificateDetailsService {

  public selectedItem: TrainingCertificateItem;
  private url = 'empresa/formacion';
  public order_query_list: DocumentoOrderQuery[];
  public filterEmployee: TrabajadorItem;

  constructor(private _httpClient: HttpClient) {
    this.order_query_list = [
      { id: 'NIF', description: 'DNI' },
      { id: 'NAME', description: 'Nombre Trabajador' }
    ];
  }

  public getTrainingCertificateDetails(_filter: TrainingCertificateDetailsItemFilter): Observable<TrainingCertificateItemDetailsResponse> {
    return this._httpClient.post<TrainingCertificateItemDetailsResponse>(Constants.apiRoot + this.url, _filter);
  }

}
