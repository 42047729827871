<div *ngIf="isLoading">
    <app-spinner></app-spinner>
</div>
<div>
    <div class="row header-title">
        <div class="col-md-8 col-lg-10 title-line s-w-70">
            <h5> {{pageTitle}} </h5>
        </div>
        <div class="col-md-4 col-lg-2 s-w-30">
            <a [routerLink]="['../trncert']">Volver</a>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-4 col-md-5">
            <div class=" activity-descrip">
                <div class="module-header">
                    <h6>Descripcion</h6>
                </div>
                <div class="module-content" id="scrollbar">
                    <ul>
                        <li *ngFor="let description of trainingDescriptionArray">
                            <pre>{{description}}</pre>
                        </li>
                    </ul>
                </div>

            </div>
            <div class=" activity-descrip d-flex p-3" *ngIf="trainingCertificateDetails.doc_generado_ok">
                <h6 class="pt-2">Descargar Registro Asistencia :</h6>
                <button class="btn-primary down-arrow ml-3"
                    (click)="downloadCompanyCertificate(trainingCertificateDetails.doc_id)">
                    <span class="fa fa-sort-desc" aria-hidden="true"></span>
                </button>
            </div>
        </div>
        <div class="col-lg-8 col-md-7">
            <form [formGroup]="filterForm" name="trainingCertificateDetailsFilterForm" #f="ngForm" novalidate
                (ngSubmit)="btnFilter()">
                <div class="row">
                    <div class="col-md-4 d-flex mb-3">
                        <ng-select #select (close)="select.blur()" loadingText="Cargando..."
                            notFoundText="No hay resultados" [items]="filterEmployees" id="filter-employee"
                            name="filter-employee" [clearable]="true" [virtualScroll]="true" bindLabel="fullName"
                            bindValue="id" placeholder="Trabajador" formControlName="fEmployeeName">
                        </ng-select>
                    </div>
                    <div class="col-md-8  s-m-b-10">
                        <button type="submit" class="btn-primary">Filtrar</button>
                    </div>
                </div>
            </form>
            <table class="table">
                <thead class="thead-light">
                    <tr>
                        <th scope="col" (click)="onChangeOrder(queryTypes[0])">
                            DNI <span class="fa" aria-hidden="true"
                            [ngClass]="{'fa-sort-desc': queryTypes[0].direction === 'DESC', 
                            'fa-sort-asc': queryTypes[0].direction === 'ASC' }"></span>
                        </th>
                        <th scope="col" (click)="onChangeOrder(queryTypes[1])">
                            Nombre Trabajador <span class="fa" aria-hidden="true"
                            [ngClass]="{'fa-sort-desc': queryTypes[1].direction === 'DESC', 
                            'fa-sort-asc': queryTypes[1].direction === 'ASC' }"></span>
                        </th>
                        <th scope="col"  class="w-9">
                            Descargar Cert.
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let participant of participantsList | paginate : paginationConfig">
                        <td>{{participant.trabajador_nif}}</td>
                        <td>{{participant.trabajador_apellidos + ' ' + participant.trabajador_nombre}}</td>
                        <td *ngIf="participant.doc_generado_ok"  class="text-center">
                            <button class="btn-primary down-arrow" type="button"
                                (click)="downloadEmployeeCertificate(participant.doc_id)">
                                <span class="fa fa-sort-desc" aria-hidden="true"></span>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="row p-l-5 pagination-div">
                <pagination-controls previousLabel="Anterior" nextLabel="Siguiente" (pageChange)="pageChanged($event)"
                    (pageBoundsCorrection)="pageChanged($event)">
                </pagination-controls>
                <span class="float-left doc-find">Total de {{recCount}} participantes encontrados</span>
            </div>
        </div>
    </div>
</div>