import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Constants } from './constants';

@Injectable({
  providedIn: 'root'
})
export class AutoReversaService {
  url = "selfappt";

  constructor(private _httpClient: HttpClient){    
  }

  public getSpecialCompany(filter: any): Observable<any> {
    return this._httpClient.post<any>(Constants.apiRoot + this.url + '/company' , filter);
  }
 
  public getAgendas(filter: any): Observable<any> {
    return this._httpClient.post<any>(Constants.apiRoot + this.url + '/agendas' , filter);
  }

  public getGainAccess(filter: any): Observable<any> {
    return this._httpClient.post<any>(Constants.apiRoot + this.url + '/gain-access' , filter);
  }

  public deleteAppointment(filter: any): Observable<any> {
    return this._httpClient.post<any>(Constants.apiRoot + this.url + '/appointment/delete' , filter);
  }

  public addAppointment(filter: any): Observable<any> {
    return this._httpClient.post<any>(Constants.apiRoot + this.url + '/appointment/add' , filter);
  }

  public getCalenderTimeSlots(filter: any): Observable<any> {
    return this._httpClient.post<any>(Constants.apiRoot + this.url + '/options' , filter);
  }

  public getPuestosvs(filter: any): Observable<any> {
    return this._httpClient.post<any>(Constants.apiRoot + this.url + '/puestosvs' , filter);
  }

}
