import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Constants } from './constants';
import { PersonRequest, PersonRequestNewAPI } from '../model/person-request';

@Injectable({
  providedIn: 'root'
})
export class ErpPersonService {

  url = "empresa/trabajadores/trabajador";
  url1 = "erp/empresa/trabajadores";

  constructor(private _httpClient: HttpClient){    
  }
  

  public getPersonList (filter: any): Observable<any> {
    return this._httpClient.post<any>(Constants.apiRoot + this.url , filter);
  }

  // public updatePersonList (filter: PersonRequest): Observable<any> {
  //   return this._httpClient.post<any>(Constants.apiRoot + this.url + '/update' , filter);
  // }

  public updatePersonList (filter: PersonRequestNewAPI): Observable<any> {
    return this._httpClient.post<any>(Constants.apiRoot + 'empresa/trabajadores/add' , filter);
  }

  public deletePerson (filter: any): Observable<any> {
    return this._httpClient.post<any>(Constants.apiRoot + 'empresa/trabajadores/delete' , filter);
  }

  public deletePersonList (filter: PersonRequest): Observable<any> {
    return this._httpClient.post<any>(Constants.apiRoot + this.url + '/delete' , filter);
  }


}
