import { SimpleClaim } from './simple-claims';
import { UserProfile } from './user-profile';
import { UserPermission } from './user-permission';

export class AuthContext {

    claims: SimpleClaim[];
  
    userPermissions: UserPermission[];

    get isAdmin(){
        return !!this.claims && !!this.claims.find(c => 
            c.type === 'role' && c.value === 'Admin')
    }

    get subjectId(){
        if (!!this.claims && !!this.claims.find(c => c.type === 'subjectid'))
        {
            return this.claims.find(c => c.type === 'subjectid').value
        }
        else{
            return  "unknown"
        }       
    }


}