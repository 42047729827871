import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CommonUiService } from '../../../shared/services/common-ui.service';
import { AuthService } from '../../../core/auth-service.component';
import { UserContext } from '../../../model/user-context';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CompaniesAllowedService } from 'src/app/core/companies-allowed.service';
import { DocCentTrabResponse } from 'src/app/model/doccenttrab-response';
import { CentrosytrabajadoresListService } from 'src/app/core/centrosytrabajadores-list.service';
import { Utils } from 'src/app/core/utils';
import { SharedDataService } from 'src/app/shared/services/shared-data.service';
import { DropdownService } from 'src/app/core/dropdown.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Constants } from 'src/app/constants/constant';
import { SelectOptions } from 'src/app/model/select-options.model';
import { PageIndexComponent } from 'src/app/modules/page-index/page-index/page-index.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  title: string;
  commonUiService: CommonUiService;
  empresaForm: FormGroup = new FormGroup({});
  public isLoading = false;
  powerBiConfigPanels:any=[];

  isLoggedIn = false;
  empresaList: SelectOptions[] = [];
  currentUserContext: UserContext = null;
  isViewlist: boolean = true;
  role: any;
  isEmpresaSelect: boolean = true;
  company: any = null;
  searchValue: string = "";
  isSearch: boolean = false;
  isCompanyUser: boolean = true;
  companyNameSearch: string = "";
  allEmprasaList: any; 

  @ViewChild('pageIndexComponent') pageIndexComponent: PageIndexComponent;

  hideMenu = true;
  htitle = '';
  error = '';
  pageAttrResp: DocCentTrabResponse;
  companyNameVal: string = "";
  private protocoloSubject: Subject<string> = new Subject();

  companyRequestData = {
    page_number: 1,
    page_size: 10,
    incl: null
  }

  constructor(
    _commonUiService: CommonUiService,
    private router: Router,
    private fb: FormBuilder,
    private dropdownService: DropdownService,
    private _companiesAllowedService: CompaniesAllowedService,
    private _authService: AuthService,
    private _sharedDataService: SharedDataService,
    private _cenTrabListService: CentrosytrabajadoresListService
  ) {

    this.commonUiService = _commonUiService;

    this._authService.loginChanges().subscribe(loggedIn => {
      this.isLoggedIn = loggedIn;
    });

    this._authService.userChangedContext().subscribe(usr => {
      this.currentUserContext = usr;
    });

    if (localStorage.getItem("company") != null) {
       let company = JSON.parse(localStorage.getItem('company'));
       this.isCompanyUser = company.userType != 'tech';
    }
    if (localStorage.getItem("role") != null) {
        this.role = JSON.parse(localStorage.getItem('role'));
        this.isViewlist = this.role.view_list;
    }
  }

  ngOnInit(): void {
    this.empresaForm = this.fb.group({
      empresa: [null, Validators.required]
    });
    this.protocoloSubject
      .pipe(debounceTime(Constants.DEBOUNCE_TIME), distinctUntilChanged())
      .subscribe((name) => this.loadTopMatchingEmprasa(name));
    this.getallowedCompanyList();
  }

  onKeyUpEmprasa(event) {
    this.companyNameSearch = event?.target?.value;     
    this.protocoloSubject.next(event?.target?.value); 
  }

  loadTopMatchingEmprasa(name: any) {    
    if (name.length >= 4) {  
      this.isSearch = true;   
      this.getallowedCompanyList(name);
    } else {
      if (name.length == 0) {
        this.isSearch = false; 
        this.getallowedCompanyList();
      }
    }
  }

  onScrollEmprasa() {
    this.isSearch = false; 
    this.getallowedCompanyList(this.companyNameSearch,true);
  }

  getallowedCompanyList(incl: string= "", isScroll = false) {
    this.isLoading = true;
    this.empresaForm.controls.empresa.enable();
    this.companyRequestData.incl = incl;
    this.companyRequestData.page_number = isScroll ? (this.companyRequestData.page_number + 1) : 1;

    this._companiesAllowedService.getAllowedCompanyList(this.companyRequestData).subscribe(res => {
      if (res) {
        if (res.webError != null) {
          this.error = res.webError.messageToUser;
          this.commonUiService.showFailToast("", this.error);
        }
        else { 
          this.allEmprasaList = res.data;
          if (isScroll) {
            this.empresaList = this.empresaList.concat(this.dropdownService.WrappedToDropDownList(res.data, 'empresa_txt', 'empresa_id'));
            this.isLoading = false;
          } else {
            this.empresaList = this.dropdownService.WrappedToDropDownList(res.data, 'empresa_txt', 'empresa_id');
            this.isLoading = false;
          }

          if (!this.isSearch && !this.isViewlist) { //res.data.length == 1 &&
            this.setCompanyUser(res.data[0]);            
          } 
          else if (this.isViewlist) {
            this.isEmpresaSelect = false;
            this.setSelectedCompany();           
          }
        }
      }
    });
  }

  setCompanyUser(result) {    
    this.createCompanyDetails(this.empresaList[0], 'company');
    this.isEmpresaSelect = true;
    this.title = result.empresa_txt;
    this.empresaForm.controls.empresa.setValue(result.empresa_id);
    this.empresaForm.controls.empresa.disable();
    this.getPageAttributes();
  }

  // setTechnicalUser(result) {
  //   this.title = result.empresa_txt;
  //   this.isEmpresaSelect = true;
  //   this.empresaForm.controls.empresa.setValue(result.empresa_id);
  //   this.empresaForm.controls.empresa.disable();
  //   this.createCompanyDetails(result,'tech');
  //   this.getPageAttributes();
  // }

  createCompanyDetails(companyDate, type: string) {
    this.isCompanyUser = type == 'tech' ? false : true;
    const companyId = companyDate.value == undefined ? companyDate.empresa_id : companyDate.value;
    const selectCompany =  this.allEmprasaList.filter(x => x.empresa_id == companyId);
  
    let companyData = {
      rowNum: 0,
      empresa_id: companyId,
      empresa_txt: companyDate.label == undefined ? companyDate.empresa_txt : companyDate.label,
      userType: type,
      cif: selectCompany[0]?.cif
    }
    localStorage.setItem('company', JSON.stringify(companyData));
  }

  setSelectedCompany() {
    this.company = JSON.parse(localStorage.getItem('company'));
    if (this.company != null) {
      this.empresaForm.controls.empresa.patchValue(this.company.empresa_id);
      this.empresaForm.controls.empresa.disable();
      this.title = this.company.empresa_txt;
      this.isEmpresaSelect = true;
      this.getPageAttributes();
    }
  }

  getPageAttributes(): void {
    this.isLoading = true;
    this.error = "";

    let companyData = {
      empresa_id: this.empresaForm.controls.empresa.value
    };
    this._cenTrabListService.getCentrosytrabajadoresList(companyData).subscribe(response => {
      if (response != null) {
        if (response.webError != null) {
          this.commonUiService.showFailToast(response.webError.messageToUser, 'errorCode');
          this.isLoading = false;
        } else {
          localStorage.setItem('centrosSource', JSON.stringify(response));
          this._sharedDataService.changeData(response);
          this.isLoading = false;
        }
      };
    }, error => this.error = Utils.formatError(error));
  }

  toggleMenu() {
    this.commonUiService.toggleMenu.emit();
  }

  goToEx() {
    this.router.navigate(['/exa']);
  }

  logout() {
    localStorage.removeItem('company');
    localStorage.removeItem('centrosSource');
    localStorage.removeItem('role');
    this._authService.logout();
    return false;
  }

  selectEmprasa(event: SelectOptions) {
    if (event != null) {
      this.title = event.label;
      this.isEmpresaSelect = true;
      this.empresaForm.controls.empresa.setValue(event.value);
      this.empresaForm.controls.empresa.disable();
      this.createCompanyDetails(event,'tech');
      this.getPageAttributes();
    }
  }

  onCloseEmprasa() {
    this.getallowedCompanyList();
    this.empresaForm.controls.empresa.enable();
    this.isEmpresaSelect = false;
    this.title = null;
    this.empresaForm.controls.empresa.reset();
    localStorage.removeItem('company');
    localStorage.removeItem('centrosSource');    
    this.router.navigate(['/pageindex']);
    this._sharedDataService.changeData(null);
  }

  

}

