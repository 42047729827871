import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { CompanyTreeViewModel } from 'src/app/model/company-tree-view-model';

@Component({
  selector: 'app-company-tree',
  templateUrl: './company-tree.component.html',
  styleUrls: ['./company-tree.component.scss']
})
export class CompanyTreeComponent implements OnInit {
  @Input() treeViewModel: Array<CompanyTreeViewModel>;
  @Output() branchSelected = new EventEmitter(); 
  constructor() { }

  ngOnInit(): void {  
  } 

  setSelectedBranchId(branchId: string, value: string): void {
    const data = { branchId, value };
    this.branchSelected.emit(data);
  }

  setSelectedInnerBranchId(data: any): void {
    this.setSelectedBranchId(data.branchId, data.value);
  }  
}
