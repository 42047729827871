import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DocumentoFileService } from 'src/app/core/documento-file.service';
import { TrabajadoresService } from 'src/app/core/trabajadores.service';
import { Utils } from 'src/app/core/utils';
import { certificates } from 'src/app/model/TrabCertificatesRespond';
import { TrabajadoreDetItem } from 'src/app/model/trabajadores-respond';
import { CommonUiService } from 'src/app/shared/services/common-ui.service';
import { DocumentUploadComponent } from '../../comp-doc/comp-doc/document-upload/document-upload.component';
import { FileDetails } from 'src/app/model/file-upload-request';
import { PreventorRequest } from 'src/app/model/PreventorRequest';
import { PreventorResponse } from 'src/app/model/PreventorResponse';

@Component({
  selector: 'app-traning-certificates',
  templateUrl: './traning-certificates.component.html',
  styleUrls: ['./traning-certificates.component.scss']
})
export class TraningCertificatesComponent implements OnInit {
  @Input() formacionesList: certificates[];
  @Input() trabajador: TrabajadoreDetItem;

  combinFormacionesList: certificates[] = [];
  diplomasList: certificates[];
  preventorList: PreventorResponse[];
  company: any;
  error: string;
  isNewDocument: boolean = false;

  constructor(public modal: NgbActiveModal,
    private trabajadoresService: TrabajadoresService ,
    private _documentoFileService: DocumentoFileService,
    private modalService: NgbModal,
    public _commonUiService: CommonUiService) { 
    this.company = JSON.parse(localStorage.getItem('company')); 
  }

  ngOnInit() { 
    this.getDiplomas();    
    this.getPreventors();
  }

  getPreventors() {
    this.combinFormacionesList = this.formacionesList;
    let convPreventorList = [];
    this.preventorList = [];
    const request : PreventorRequest = {
     CompanyAllowedCIF: this.company.cif,
     CompanyAllowedNIF: this.trabajador.trabajador_nif
    };

    this.trabajadoresService.getAllPreventor(request).subscribe(res => {
      if (res.length > 0) {
        this.preventorList = res;
        res.forEach(element => {
          let certificateId = element.certificateId.toString();
          const data = {
            curso_inicio: element.courseBegin,
            curso_fin: element.courseEnd,
            curso_txt: element.courseTitle,
            documento_id: certificateId,
            documento_txt: element.pdF_NAME,
            curso_id: null,
            curso_duracion: null,
            fecha_diplomatura: null,
            fecha_subido: null
          }
          convPreventorList.push(data);
        });      
        this.combinFormacionesList = [...this.formacionesList,...convPreventorList];
      } else {        
        this.combinFormacionesList = this.formacionesList;
      }
    });  
  }

  getDiplomas() {
    const request = {
      empresa_id: this.company.empresa_id,
      trabajador_id: this.trabajador.trabajador_id,
      show_formaciones: false
    }     

    this.trabajadoresService.getCertificates(request).subscribe(res => {
      if (res && res.webError == null) {
        this.diplomasList = res.data;
      } else {
        this._commonUiService.showFailToast(" ", res.webError.messageToUser);
      }
    });  
  }

  downloadFormacionDoc(documentId: string) { 
    
    if(!this.downloadPreventorDocument(documentId)) {
      let documentoReq = {
        id_documento: documentId
      }
      this._documentoFileService.downloadFormacionFile(documentoReq).subscribe(res => {
        if (res != null) {
          if (res.webError != null) {
            this._commonUiService.showFailToast(" ", res.webError.messageToUser);
          }
          else {
            const linkSource = 'data:application/pdf;base64,' + res.data.fichero + '\n';
            const downloadLink = document.createElement('a');
            const fileName = res.data.nombre;
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
          }
        }
      }, error => this.error = Utils.formatError(error));
    }  
    
  }

  downloadPreventorDocument(documentId: string) {
    let docDetails = this.preventorList.filter(x => x.certificateId.toString() == documentId);
    if (docDetails.length > 0) {
      const downloadLink = document.createElement('a');
      downloadLink.href = docDetails[0]["pdF_URL"];
      downloadLink.download = docDetails[0]["pdF_NAME"];
      downloadLink.click();
      return true;
    } else {
      return false;
    }
  }

  downloadDiplomasDoc(documentId: string) {
    let documentoReq = {
      id_documento: documentId
    }
    this._documentoFileService.getDocumentoFile(documentoReq).subscribe(res => {
      if (res != null) {
        if (res.webError != null) {
          this._commonUiService.showFailToast(" ", res.webError.messageToUser);
        }
        else {
          const linkSource = 'data:application/pdf;base64,' + res.data.fichero + '\n';
          const downloadLink = document.createElement('a');
          const fileName = res.data.nombre;
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        }
      }
    }, error => this.error = Utils.formatError(error));
  }

  
  uploadDocument(fileDocRequest: FileDetails = null) {
    const modalRef = this.modalService.open(DocumentUploadComponent, { centered: true, size: 'md' });
    modalRef.componentInstance.fileDocumentDetails = fileDocRequest;
    modalRef.componentInstance.id_ubicacion = this.company.empresa_id, 
    modalRef.componentInstance.trabajador_id = this.trabajador.trabajador_id,
    modalRef.componentInstance.mode = "certi";
    modalRef.componentInstance.isSaved.subscribe((res) => {
      if (res == true) {
        this.getDiplomas();
      }
    });   
  }
  
  tabClick(id: number) { 
    this.isNewDocument = id == 1 ? false : true;
  }



}
