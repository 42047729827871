/*
  object sample:
  {
    "trabajador": null,
    "fecha_fin_desde": "20190101",
    "fecha_fin_hasta": "20201231",
    "order_by": "NAME",
    "top_n": 10,
    "page": 1
   }
*/
export class TrainingCertificateListFilter {
    empresa_id?: string;
    trabajador: string;
    fecha_fin_desde: string;
    fecha_fin_hasta: string;
    order_by: string;
    top_n: number;
    page: number;

    public constructor(init?: Partial<TrainingCertificateListFilter>) {
        Object.assign(this, init);
    }
}
