import { Component, OnInit, Input } from '@angular/core';
import { TrabajadorItem } from '../../../model/trabajador-item';
import { DocCentTrabResponse } from '../../../model/doccenttrab-response';
import { CentrosirabajadorItem } from '../../../model/centrosirabajador-item';
import { Utils } from '../../../core/utils';
import { FormGroup, FormControl } from '@angular/forms';
import { CommonUiService } from 'src/app/shared/services/common-ui.service';
import { TrainingCertificateListFilter } from 'src/app/model/training-certificate-list-filter';
import { DocumentoOrderQuery } from 'src/app/model/documento-order-query';
import { TrainingCertificateListService } from 'src/app/core/training-certificate-list.service';
import { TrainingCertificateListResponse } from 'src/app/model/training-certificate-list-response';
import { TrainingCertificateItem } from 'src/app/model/training-certificate-item';
import { TrainingCertificateDetailsService } from 'src/app/core/training-certificate-details.service';
import { Router } from '@angular/router';
import { NgxPaginationConfig } from 'src/app/model/ngx-pagination-config';
import { TrabajadoresDiplomasFilter } from 'src/app/model/trabajadores-diplomas-filter';
import { TrabajadoresDiplomasItem } from 'src/app/model/Trabajadores-Diplomas-Item';
import { TrabajadoreDetItem } from 'src/app/model/trabajadores-respond';
import { TrabajadoresService } from 'src/app/core/trabajadores.service';
import { certificates } from 'src/app/model/TrabCertificatesRespond';
import { TraningCertificatesComponent } from '../../trabajadore/traning-certificates/traning-certificates.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-trn-cert',
  templateUrl: './trn-cert.component.html',
  styleUrls: ['./trn-cert.component.scss']
})
export class TrnCertComponent implements OnInit {

  error: string;
  filterEmployees: TrabajadorItem[];
  allEmployees: TrabajadorItem[];
  pageAttrResp: DocCentTrabResponse;
  centrerEmployees: CentrosirabajadorItem[];
  company: any;

  certificates: TrainingCertificateItem[];
  diplomados: TrabajadoresDiplomasItem[];
  isLoading = false;
  filter: TrainingCertificateListFilter;
  queryTypesCertif: DocumentoOrderQuery[];
  queryTypesDiplom: DocumentoOrderQuery[];
  responseList: TrainingCertificateListResponse;
  filterDiplomas: TrabajadoresDiplomasFilter;
  selectedTab: number = 1;
  trabajadoreSelect: string = "";

  recCountCertif = 0;
  recCountDiplom = 0;
  recPP = 10;
  pageNumCertif = 1;
  pageCntCertif = 1;
  pageNumDiplom = 1;
  pageCntDiplom = 1;
  orderByCertif: string;
  orderByDiplom: string;

  filterEmployee: TrabajadorItem;
  paginationConfigCertif: NgxPaginationConfig;
  paginationConfigDiplom: NgxPaginationConfig;

  @Input() public filterForm = new FormGroup({
    trabajadores: new FormControl(null),
    fEmployeeName: new FormControl(null),
    filterFinDesdeDate: new FormControl(null),
    filterFinHastaDate: new FormControl(null)
  });

  constructor(
    private _trainingCertificateListService: TrainingCertificateListService,
    public _commonUiService: CommonUiService,
    private modalService: NgbModal,
    private trabajadoresService: TrabajadoresService,
    private _tariningCertificateDetailsService: TrainingCertificateDetailsService,
    private _router: Router) {
      this.company = JSON.parse(localStorage.getItem('company'));
     }

  ngOnInit(): void {    
    this.intilizeGridSettings();    
    this.applyFilter();
    this.getPageAttributes();
  }

  intilizeGridSettings() {  
    this.paginationConfigCertif = new NgxPaginationConfig();
    this.paginationConfigCertif.id = "pagination2";
    this.paginationConfigCertif.itemsPerPage = this.recPP;
    this.queryTypesCertif = this._trainingCertificateListService.order_query_list_Certi;    
    this.queryTypesCertif[0].direction = 'ASC';
    this.orderByCertif = 'NAME';

    this.paginationConfigDiplom = new NgxPaginationConfig();
    this.paginationConfigDiplom.id = "pagination1";
    this.paginationConfigDiplom.itemsPerPage = this.recPP;
    this.queryTypesDiplom = this._trainingCertificateListService.order_query_list_Diplom;    
    this.queryTypesDiplom[0].direction = 'ASC';
    this.orderByDiplom = 'NAME';
  }

  getPageAttributes(): void {
    var retrievedObject = localStorage.getItem('centrosSource');
    this.pageAttrResp = JSON.parse(retrievedObject);
    if (this.pageAttrResp != null) {
      if (this.pageAttrResp.webError != null) {
        this.error = this.pageAttrResp.webError.messageToUser;
      } else {
        this.allEmployees = this.pageAttrResp.data.trabajadores.map((i) => { i.fullName = i.apellidos + ' ' + i.nombre; return i; });
        this.filterEmployees = [];
        this.allEmployees.forEach(emp => {
          this.filterEmployees.push(emp);
        });
        this.centrerEmployees = this.pageAttrResp.data.centrosTrabajadores;
      }
    }
  }

  selectEmployee(selectedOption: any) {
    this.filterEmployee = selectedOption;
    this._tariningCertificateDetailsService.filterEmployee = selectedOption;
    this.applyFilter();
  }

  applyFilter() {
    this.isLoading = true;
    if (this.filterForm.valid) { 
      if (this.selectedTab == 2) {
        const filterFinDesdeDate = this.filterForm.get('filterFinDesdeDate').value;
        const filterFinHastaDate = this.filterForm.get('filterFinHastaDate').value;
        this.filter = new TrainingCertificateListFilter({
          trabajador: this.filterEmployee?.id,
          fecha_fin_desde: filterFinDesdeDate ? this._commonUiService.dateToString(filterFinDesdeDate) : '',
          fecha_fin_hasta: filterFinHastaDate ? this._commonUiService.dateToString(filterFinHastaDate) : '',
          top_n: this.recPP,
          page: this.pageNumCertif,
          order_by: this.orderByCertif,
          empresa_id : this.company.empresa_id
        });
        this.getTrainingCertificateList();
      } else if (this.selectedTab == 1) {
        this.filterDiplomas = new TrabajadoresDiplomasFilter({
          empresa_id: this.company.empresa_id ,
          incl: this.trabajadoreSelect,
          order_by: this.orderByDiplom,
          page_number: 1, 
          page_size: 20
        });

        this.getTrabajadorDiplomas();
      }
    } else {
      this.error = 'Valores de filtro no válidos';
      this._commonUiService.showWarningMessage(this.error);
      this.isLoading = false;
    }
  }

  onChangeOrderCertif(orderByElement: DocumentoOrderQuery) {
    const direction = orderByElement.direction === 'ASC' ? 'DESC' : 'ASC';
    this.queryTypesCertif.forEach((e, i) => {
      e.direction = '';
    });
    this.queryTypesCertif.filter(e => e.id === orderByElement.id)[0].direction = direction;
    this.orderByCertif = direction === 'ASC' ? orderByElement.id : orderByElement.id + ' DESC';
    this.applyFilter();
  }

  onChangeOrderDiplom(orderByElement: DocumentoOrderQuery) {
    const direction = orderByElement.direction === 'ASC' ? 'DESC' : 'ASC';
    this.queryTypesDiplom.forEach((e, i) => {
      e.direction = '';
    });
    this.queryTypesDiplom.filter(e => e.id === orderByElement.id)[0].direction = direction;
    this.orderByDiplom = direction === 'ASC' ? orderByElement.id : orderByElement.id + ' DESC';
    this.applyFilter();
  }

  btnFilter() {
    this.pageNumCertif = 1;
    this.pageNumDiplom = 1;
    this.paginationConfigCertif.currentPage = this.pageNumCertif;
    this.paginationConfigDiplom.currentPage = this.pageNumDiplom;
    this.applyFilter();
  }

  getTrabajadorDiplomas() {
    
    this._trainingCertificateListService.getTrabajadoreDiplomadoList(this.filterDiplomas).subscribe(result => {     
      if (result != null && result.webError != null) {
        this.error = result.webError.messageToUser;
        this._commonUiService.showWarningMessage(this.error);
        this.isLoading = false;
      } else {
        this.diplomados = result.data;
        this.recCountDiplom = result.links.rowcount;
        this.pageCntDiplom = result.links.rowcount === 0 || !result.links.rowcount ? 1 : Math.ceil(result.links.rowcount / this.recPP);
        this.paginationConfigDiplom.totalItems = this.recCountDiplom;
        this.isLoading = false;
      }      
    }, error => {
      this.error = Utils.formatError(error);
      this._commonUiService.showWarningMessage(this.error);
      this.isLoading = false;
    });
  }

  getTrainingCertificateList(): void {
    this.error = '';    // erase previous errors
    this.isLoading = true;
    if(this.company.userType == 'tech') {
      this.filter.empresa_id = this.company.empresa_id
    } 

    this._trainingCertificateListService.getTrainingCertificateList(this.filter).subscribe(response => {
      this.responseList = response;
      if (this.responseList != null) {
        if (this.responseList.webError != null) {
          this.error = this.responseList.webError.messageToUser;
          this._commonUiService.showWarningMessage(this.error);
        } else {
          this.certificates = response.data;
          this.recCountCertif = response.links.rowcount;
          this.pageCntCertif = response.links.rowcount === 0 || !response.links.rowcount ? 1 : Math.ceil(response.links.rowcount / this.recPP);
          this.paginationConfigCertif.totalItems = this.recCountCertif;
        }
      }
      this.isLoading = false;
    }, error => {
      this.error = Utils.formatError(error);
      this._commonUiService.showWarningMessage(this.error);
      this.isLoading = false;
    });
  }

  gotoTrainingDetails(certificateItem: TrainingCertificateItem) {
    localStorage.setItem('SelectedItem', JSON.stringify(certificateItem));
    this._router.navigate(['/trninfo'], { queryParams: { id: certificateItem.id } });
  }

  pageChangedCertificate(pageNumber: number): void {
    if (pageNumber > 0 && pageNumber <= this.pageCntCertif && this.pageNumCertif !== pageNumber) {
      this.paginationConfigCertif.currentPage = pageNumber;
      this.pageNumCertif = pageNumber;
      this.paginationConfigDiplom.currentPage = 1;
      this.pageNumDiplom = 1;
      this.applyFilter();
    }
  }

  pageChangedDiplomas(pageNumber: number): void {
    if (pageNumber > 0 && pageNumber <= this.pageCntDiplom && this.pageNumDiplom !== pageNumber) {
      this.paginationConfigDiplom.currentPage = pageNumber;
      this.pageNumDiplom = pageNumber;      
      this.applyFilter();
    }
  }

  tabClick(tab: number) {
    this.selectedTab = tab;
    this.applyFilter();
  }

  getCertificates(trabajador: TrabajadoreDetItem) {
    var request = {
      empresa_id: this.company.empresa_id,
      trabajador_id: trabajador.trabajador_id,
      show_formaciones: true
    }     

    this.trabajadoresService.getCertificates(request).subscribe(res => {
      if (res && res.webError == null) {
        this.openCertificateModal(res.data , trabajador);
      } else {
        this._commonUiService.showFailToast(" ", res.webError.messageToUser);
      }
    });  
  }

  openCertificateModal(certificateList: certificates[] , trabajador: TrabajadoreDetItem) {
    const modalRef = this.modalService.open(TraningCertificatesComponent, { centered: true, size: 'lg' });
    modalRef.componentInstance.formacionesList = certificateList;
    modalRef.componentInstance.trabajador = trabajador;
  }

  onKeyUpTrabajadores(event) {
    if (event?.target?.value.length >= 4) {
      this.trabajadoreSelect = event?.target?.value;
      this.applyFilter();
    } else if (event?.target?.value.length == 0) {
      this.trabajadoreSelect = event?.target?.value;
      this.applyFilter();
    }
  }

}
