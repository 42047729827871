import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../core/auth-service.component';
import { Router } from '@angular/router';

@Component({
    selector: 'app-signin-callback',
    template: `<div></div>`
})
export class SigninRedirectCallbackComponent implements OnInit {

    constructor(private _authService: AuthService, private _router: Router) {

    }

    ngOnInit() {
        localStorage.removeItem('role');
        this._authService.completeLogin().then((user) => {
            if (user != null) {
                this.getUserRoles();
                const loggedUser = {
                    username: (!user.profile.username) ? '' : user.profile.username,
                    fullName: (!user.profile.full_name) ? '' : user.profile.full_name,
                    id: user.profile.sub,
                    sid: user.profile.sid,
                    gesemeApiToken: user.access_token,
                    permissionScope: user.scope,
                    accessLevel: ''
                };
                localStorage.setItem('loggeduser', JSON.stringify(loggedUser));
                this._router.navigate(['/pageindex'], {replaceUrl: true}).then();
            }
            else {
                this._router.navigate(['/signin'], {replaceUrl: true}).then();
            }
        }).catch(error => {
            this._router.navigate(['/signin'], {replaceUrl: true}).then();
        });
    }


    getUserRoles(){
        this._authService.getUserRolePermission().subscribe(res => {
          if (res) {
            localStorage.setItem('role', JSON.stringify(res.data));
          }
        });
      }
    
}
