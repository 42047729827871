import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { AutoReversaService } from 'src/app/core/auto-reversa.service';
import { AppoinmentConfirmationComponent } from '../appoinment-confirmation/appoinment-confirmation.component';


@Component({
  selector: 'app-calender',
  templateUrl: './calender.component.html',
  styleUrls: ['./calender.component.scss']
})
export class CalenderComponent implements OnInit {
  events: any = [];
  selectedDate: any;
  selectedEvent: any;
  messageToUser: any;

  constructor(private modalService: NgbModal,
    public modal: NgbActiveModal,
    private autoreversaService: AutoReversaService) { }

  ngOnInit(): void {

  }
  setSelected(date: any) {
    this.getCalenderTimeslots(date);
    this.selectedDate = date;
  }

  getCalenderTimeslots(currentDate: any) {
    this.messageToUser = "";
    this.events = [];
    const userDetails = JSON.parse(localStorage.getItem("employeeDetails") || 'null');
    const timeSlotData = {
      "agenda_id": userDetails.agenda_id,
      "from": moment(currentDate).format('yyyyMMDD'),
      "days": 1,
      "dni": userDetails.dni,
      "fecha_nacimiento": userDetails.fecha_nacimiento
    };

    this.autoreversaService.getCalenderTimeSlots(timeSlotData).subscribe(res => {
      if (res.webError == null && res.data && res.data[0].slots.length > 0) {
        const slots = res.data[0].slots;
        slots.forEach((element: any) => {
          const dateArray = res.data[0].date.split('/');
          const date = new Date(dateArray[2], dateArray[1] - 1, dateArray[0]);
          this.events.push({ title: element.hour, start: date, end: date, color: '#5bc2ee', enabled: element.enabled },
          )
        });
      }
      else if (res.webError == null && res.data && res.data[0].slots.length == 0) {
        this.messageToUser = res.data[0].messageToUser;
      }
    });
  }

  handleCalender(event: any) {
    if (event.enabled) {      
      const userDetails = JSON.parse(localStorage.getItem("employeeDetails") || 'null');
      userDetails.fecha = moment(this.selectedDate).format('yyyyMMDD');
      userDetails.hora = event.title;
      localStorage.setItem("employeeDetails", JSON.stringify(userDetails));
      this.modal.dismiss();
      this.modalService.open(AppoinmentConfirmationComponent, { centered: true , size: 'md' });
    }
  }


}

