import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TitleService {

  public title = new BehaviorSubject('');
  public footer = new BehaviorSubject('');

  setTitle(sTitle: string) {
    this.title.next(sTitle);
  }

  setFooter(sFooter: string) {
    this.footer.next(sFooter);
  }

}
