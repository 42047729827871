import { Component, OnInit, Input } from '@angular/core';
import { DocumentosListService } from '../../../core/documento-list.service';
import { FormGroup, FormControl } from '@angular/forms';
import { Utils } from '../../../core/utils';
import { DocumentoListFilter } from '../../../model/documento-list-filter';
import { DocumentoListResponse } from '../../../model/documento-list-response';
import { DocumentoFileQuery } from '../../../model/documento-file-query';
import { DocumentoFileService } from '../../../core/documento-file.service';
import { DocumentoFileResponse } from '../../../model/documento-file-response';
import { DocumentoOrderQuery } from '../../../model/documento-order-query';
import { DocCentTrabResponse } from '../../../model/doccenttrab-response';
import { CentroItem } from '../../../model/centro-item';
import { TrabajadorItem } from '../../../model/trabajador-item';
import { DocumentoItem } from '../../../model/documento-item';
import { DatePipe } from '@angular/common';
import { CommonUiService } from '../../../shared/services/common-ui.service';
import { CentrosirabajadorItem } from '../../../model/centrosirabajador-item';
import * as JSZip from 'jszip';
import * as FileSaver from 'file-saver';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { NgxPaginationConfig } from 'src/app/model/ngx-pagination-config';
import { DateTimeAdapter } from '@busacca/ng-pick-datetime';

@Component({
  selector: 'app-health-reports',
  templateUrl: './health-reports.component.html',
  styleUrls: ['./health-reports.component.scss']
})
export class HealthReportsComponent implements OnInit {
  isLoading = false;

  displayedColumns = [
    'cen_nombre',
    'trab_apell',
    'nombre',
    'f_reco',
    'f_doc',
    'id',
    'action'
  ];

  error: string;
  responseList: DocumentoListResponse;
  responseFile: DocumentoFileResponse;
  pageAttrResp: DocCentTrabResponse;

  filterCenters: CentroItem[];
  filterEmployees: TrabajadorItem[];
  allEmployees: TrabajadorItem[];
  centrerEmployees: CentrosirabajadorItem[];

  filterCenter: CentroItem;
  filterEmployee: TrabajadorItem;

  documents: DocumentoItem[];
  filter: DocumentoListFilter;
  queryTypes: DocumentoOrderQuery[];

  recCount: number = 0;
  recPP: number = 10;
  pageNum: number = 1;
  pageCnt: number = 1;
  orderBy: string;
  company: any;

  selected_query: string;       // this is the selected query: ngOnInit set to "TODAS"

  filesArray: string[];

  paginationConfig: NgxPaginationConfig;

  @Input() public filterForm = new FormGroup({
    fCenterName: new FormControl(null),
    fEmployeeName: new FormControl(null),
    filterRecoDesdeDt: new FormControl(null),
    filterDocDesdeDt: new FormControl(null),
    filterRecoHastaDt: new FormControl(null),
    filterDocHastaDt: new FormControl(null),
  });

  constructor(
    private _documentoListService: DocumentosListService,
    private _documentoFileService: DocumentoFileService,
    private _datePipe: DatePipe,
    dateTimeAdapter: DateTimeAdapter<any>,
    public _commonUiService: CommonUiService,
    private httpClient: HttpClient,) {
    dateTimeAdapter.setLocale('es-ES');
    this.company = JSON.parse(localStorage.getItem('company'));
  }

  ngOnInit(): void {
    this.paginationConfig = new NgxPaginationConfig();
    this.paginationConfig.itemsPerPage = this.recPP;
    this.queryTypes = this._documentoListService.order_query_list;
    this.orderBy = 'FECHA_RECONOCIMIENTO DESC';
    this.queryTypes[3].direction = 'DESC';
    this.applyFilter();
    this.getPageAttributes();
    
  }

  getPageAttributes(): void {
    var retrievedObject = localStorage.getItem('centrosSource');
    this.pageAttrResp = JSON.parse(retrievedObject);
    if (this.pageAttrResp != null) {
      if (this.pageAttrResp.webError != null) {
        this.error = this.pageAttrResp.webError.messageToUser;
      }
    }
    this.filterCenters = this.pageAttrResp.data.centros;
    this.allEmployees = this.pageAttrResp.data.trabajadores.map((i) => { i.fullName = i.nombre + ' ' + i.apellidos; return i; });
    this.filterEmployees = [];
    this.allEmployees.forEach(emp => {
      this.filterEmployees.push(emp);
    });
    this.centrerEmployees = this.pageAttrResp.data.centrosTrabajadores;
  }

  getDocumentosList(): void {
    this.error = '';    // erase previous errors
    this.isLoading = true;
    if(this.company.userType == 'tech') {
      this.filter.empresa_id = this.company.empresa_id
    }

    this._documentoListService.getDocumentosList(this.filter).subscribe(response => {
      this.responseList = response;
      if (this.responseList != null) {
        if (this.responseList.webError != null) {
          this.error = this.responseList.webError.messageToUser;
          this._commonUiService.showWarningMessage(this.error);
        } else {
          this.documents = response.data;
          this.recCount = response.links.rowcount;
          this.pageCnt = (response.links.rowcount === 0 || !response.links.rowcount) ? 1 : Math.ceil(response.links.rowcount / this.recPP);
          this.paginationConfig.totalItems = this.recCount;
        }
      }
      this.isLoading = false;
    }, error => {
      this.error = Utils.formatError(error);
      this.isLoading = false;
    });
  }

  getDocumentoFile(id_documento: string): void {
    let docFile: DocumentoFileQuery;
    docFile = { id_documento: id_documento };
    this.error = '';    // erase previous errors
    this._documentoFileService.getDocumentoFile(docFile).subscribe(response => {
      this.responseFile = response;
      if (this.responseFile != null) {
        if (this.responseFile.webError != null) {
          this.error = this.responseFile.webError.messageToUser;
          this._commonUiService.showWarningMessage(this.error);
        }
        else {
          const linkSource = 'data:application/pdf;base64,' + this.responseFile.data.fichero + '\n';
          const downloadLink = document.createElement('a');
          const fileName = this.responseFile.data.nombre;
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        }
      }

    }, error => this.error = Utils.formatError(error));
  }

  getZipDocumentoFile(id_documento: string): void {

    let docFile: DocumentoFileQuery;
    docFile = { id_documento: id_documento };
    this.error = '';    // erase previous errors
    this._documentoFileService.getDocumentoFile(docFile).subscribe(response => {
      this.responseFile = response;
      if (this.responseFile != null) {
        if (this.responseFile.webError != null) {
          this.error = this.responseFile.webError.messageToUser;
        }
        else {
          const linkSource = 'data:application/pdf;base64,' + this.responseFile.data.fichero + '\n';
          const downloadLink = document.createElement('a');
          const fileName = this.responseFile.data.nombre;
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          this.filesArray.push(linkSource);
        }
      }
    }, error => this.error = Utils.formatError(error));
    this.downloadFile();
  }

  downloadFile() {
    this.createZip(this.filesArray, 'Sample');
  }

  async getFile(url: string) {
    const httpOptions = {
      responseType: 'blob' as 'json'
    };
    const res = await this.httpClient.get(url, httpOptions).toPromise().catch((err: HttpErrorResponse) => {
      const error = err.error;
      return error;
    });
    return res;
  }

  async createZip(files: any[], zipName: string) {
    const zip = new JSZip();
    const name = zipName + '.zip';
    // tslint:disable-next-line:prefer-for-of
    for (let counter = 0; counter < files.length; counter++) {
      const element = files[counter];
      const fileData: any = await this.getFile(element);
      const b: any = new Blob([fileData], { type: '' + fileData.type + '' });
      zip.file(element.substring(element.lastIndexOf('/') + 1), b);
    }
    zip.generateAsync({ type: 'blob' }).then((content) => {
      if (content) {
        FileSaver.saveAs(content, name);
      }
    });
  }

  onChangeOrder(orderByElement: DocumentoOrderQuery) {
    const direction = orderByElement.direction === 'ASC' ? 'DESC' : 'ASC';
    this.queryTypes.forEach((e, i) => {
      e.direction = '';
    });
    this.queryTypes.filter(e => e.id === orderByElement.id)[0].direction = direction;
    this.orderBy = direction === 'ASC' ? orderByElement.id : orderByElement.id + ' DESC';
    this.applyFilter();
  }

  btnFilter() {
    this.pageNum = 1;
    this.paginationConfig.currentPage = this.pageNum;
    this.applyFilter();
  }

  selectCenter(selectedOption: any) {
    this.filterCenter = selectedOption;

    this.filterEmployee = null;
    if (selectedOption != null) {
      this.filterEmployees.length = 0;
      const li = [];
      this.centrerEmployees.forEach(emp => {
        if (emp.id_centro === selectedOption.id) {
          const a = this.allEmployees.find(item => item.id === emp.id_trabajador);
          li.push(a);
        }
      });
      this.filterEmployees = li;
    } else {
      const li = [];
      this.allEmployees.forEach(emp => {
        li.push(emp);
      });
      this.filterEmployees = li;
    }

  }

  selectEmployee(selectedOption: any) {
    this.filterEmployee = selectedOption;
  }

  dateToSpanish(dateToFormat: string): string {
    return this._datePipe.transform(dateToFormat, 'dd/MM/yyyy');
  }

  dateToString(dateToFormat: Date): string {
    return this._datePipe.transform(dateToFormat, 'yyyyMMdd');
  }
  
  applyFilter() {
    const filterRecoDesdeDt = this.filterForm.get('filterRecoDesdeDt').value;
    const filterRecoHastaDt = this.filterForm.get('filterRecoHastaDt').value;
    const filterDocDesdeDt = this.filterForm.get('filterDocDesdeDt').value;
    const filterDocHastaDt = this.filterForm.get('filterDocHastaDt').value;
    this.filter = new DocumentoListFilter({
      centro: this.filterCenter?.id,
      trabajador: this.filterEmployee?.id,
      fecha_reconocimiento_desde: filterRecoDesdeDt != null ? this.dateToString(filterRecoDesdeDt) : '',
      fecha_reconocimiento_hasta: filterRecoHastaDt != null ? this.dateToString(filterRecoHastaDt) : '',
      fecha_documento_desde: filterDocDesdeDt != null ? this.dateToString(filterDocDesdeDt) : '',
      fecha_documento_hasta: filterDocHastaDt != null ? this.dateToString(filterDocHastaDt) : '',
      top_n: this.recPP,
      page: this.pageNum,
      order_by: this.orderBy
    }
    );
    this.getDocumentosList();
  }

  pageChanged(pageNumber: number): void {
    if (pageNumber > 0 && pageNumber <= this.pageCnt && this.pageNum !== pageNumber) {
      this.paginationConfig.currentPage = pageNumber;
      this.pageNum = pageNumber;
      this.applyFilter();
    }
  }

  downloadAllDocumentoFiles() : void{
    this.getDocumentosList();
    this.documents.forEach(element => {
      this.getDocumentoFile(element.id);
    });
  }

}
