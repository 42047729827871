import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { ExcelExportService } from 'src/app/core/excel-export.service';
import { TrabajadoresService } from 'src/app/core/trabajadores.service';
import { CentroItem } from 'src/app/model/centro-item';
import { DocCentTrabResponse } from 'src/app/model/doccenttrab-response';
import { NgxPaginationConfig } from 'src/app/model/ngx-pagination-config';
import { TrabajadoresRequest } from 'src/app/model/trabajadores-request';
import { TrabajadoreDetItem } from 'src/app/model/trabajadores-respond';
import { CommonUiService } from 'src/app/shared/services/common-ui.service';

@Component({
  selector: 'app-trabajadore-inactive',
  templateUrl: './trabajadore-inactive.component.html',
  styleUrls: ['./trabajadore-inactive.component.scss']
})
export class TrabajadoreInactiveComponent implements OnInit {
  @Input() inactiveEvent: Observable<boolean>;
  isActive: boolean = false;  
  company: any;
  error: string;
  pageAttrResp: DocCentTrabResponse;
  filterCenters: CentroItem[];

  trabajadoreRequest: TrabajadoresRequest;
  trabInactiveList: TrabajadoreDetItem[];
  private inactiveSubscription: Subscription;

  public inactiveForm = new FormGroup({
    centro: new FormControl(null),
    trabajador: new FormControl(null)
  });

  public inPaginationConfig = new NgxPaginationConfig();
  
  constructor(private trabajadoresService: TrabajadoresService ,
    private _commonUiService: CommonUiService,
    private _excelExportService: ExcelExportService) { 
      this.company = JSON.parse(localStorage.getItem('company')); 
  }

  ngOnInit() {    
    this.inPaginationConfig.id = 'pending-inactive-list-pagination';
    this.inPaginationConfig.currentPage = 1;
    this.inPaginationConfig.itemsPerPage = 10;
    this.trabInactiveList = [];
    this.getPageAttributes(); 
    this.inactiveSubscription = this.inactiveEvent.subscribe(res => {      
      this.isActive = res;
      if(!this.isActive) {
        this.getTrabajadore();
      }      
    });       
  } 

  ngOnDestroy() {
    this.inactiveSubscription.unsubscribe();
  }
  
  getTrabajadore() {     
    this.inactiveSubscription = this.inactiveEvent.subscribe(res => {      
      this.isActive = res;
    });

    this.trabajadoreRequest = {
      empresa_id: this.company.empresa_id,
      centro_id: this.inactiveForm.controls.centro.value,
      trabajador_txt: this.inactiveForm.controls.trabajador.value,
      activos: this.isActive,
      aptos_check: false,
      no_aptos_check: false,
      todos_check: true,
    }    

    this.trabajadoresService.getTrabajadores(this.trabajadoreRequest).subscribe(res => {
      if (res) {
        if(res.webError == null) {
          this.inPaginationConfig.totalItems = res.data.length;
          this.inPaginationConfig.currentPage = 1;
          this.trabInactiveList = res.data;
        } else {
          this._commonUiService.showFailToast(" ", res.webError.messageToUser);
        }
      }
    });    
  }

  getPageAttributes(): void {
    var retrievedObject = localStorage.getItem('centrosSource');
    this.pageAttrResp = JSON.parse(retrievedObject);
    if (this.pageAttrResp != null) {
      if (this.pageAttrResp.webError != null) {
        this.error = this.pageAttrResp.webError.messageToUser;
      } else {
        this.filterCenters = this.pageAttrResp.data.centros;
      }       
    }     
  }

  public pendingPageChangedInactive(pageNo: number): void {
    this.inPaginationConfig.currentPage = pageNo;
  }

  onSearchTrabajador() {
    this.getTrabajadore();
  }

  selectCentro() {
    this.getTrabajadore();
  }

  onAddTrabajadore(trabajadorid: string) {
    let trabajadorReq = {
      trabajador_id: trabajadorid
    }

    this.trabajadoresService.enableTrabajadores(trabajadorReq).subscribe(res => {
      if (res) {
        if (res.webError == null) {
          if (res.data.trabajador_id == trabajadorid) {
            this._commonUiService.showSuccessToast("Habilitado con éxito !!!");
            this.getTrabajadore();
          }          
        } else {
          this._commonUiService.showFailToast(" ", res.webError.messageToUser);
        }
      }
    });
  }

  downloadExcel() {
    let dataToExcel = [];
    this.trabInactiveList.forEach(e => {
      dataToExcel.push(
        {
          NIF: e.trabajador_nif,
          NombreTrabajador: e.trabajador_txt,
          Telefono: e.trabajador_telef,
          Email: e.trabajador_email
        }
      )      
    });
    this._excelExportService.exportAsExcelFile(dataToExcel, "Trabajadores_inactivos");
  }

  onKeyUpTrabajadorInactive(event) {
    if(event.target.value == "") {
      this.getTrabajadore();
    }
  }

}
