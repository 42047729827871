<div class="side-nav">
  <ul class="menu1">
    <li>
      <a [routerLink]="[]">
        <button (click)="navigate('')">
          <span>{{'menu.menu1' | translate}}</span>
          <span class="fa fa-fw fa-angle-right pull-right"></span>
        </button>
      </a>
    </li>
    <li>
      <a [routerLink]="[]">
        <button (click)="navigate('')">
          <span>{{'menu.menu2' | translate}}</span>
          <span class="fa fa-fw fa-angle-right pull-right"></span>
        </button>
      </a>
    </li>
    <li>
      <a [routerLink]="[]">
        <button (click)="navigate('')">
          <span>{{'menu.menu3' | translate}}</span>
          <span class="fa fa-fw fa-angle-right pull-right"></span>
        </button>
      </a>
    </li>
    <li>
      <a [routerLink]="[]">
        <button (click)="navigate('')">
          <span>{{'menu.menu4' | translate}}</span>
          <span class="fa fa-fw fa-angle-right pull-right"></span>
        </button>
      </a>
    </li>
    <li [hidden]="true" class="colspan">
      <a href="#">
        <button (click)="openCompany = !openCompany">
          <span>Company</span>
          <span class="fa fa-fw fa-angle-down pull-right"></span>
        </button>
      </a>
      <ul id="submenu-1" [ngClass]="{'collapse' : openCompany}">
        <li>
          <a href="#">
            <button><span>Nam varius du</span></button>
          </a>
        </li>
        <li>
          <a href="#">
            <button><span>Aenean commodo</span></button>
          </a>
        </li>
      </ul>
    </li>
    <li [hidden]="true">
      <a href="#">
        <button>
          <span>Sed faucibus</span>
          <span class="fa fa-fw fa-angle-right pull-right"></span>
        </button>
      </a>
    </li>
  </ul>
    <ul>
    <li [hidden]="true">
      <a href="#">
        <button>
          <span>Performance Management</span>
          <span class="fa fa-fw fa-angle-right pull-right"></span>
        </button>
      </a>
    </li>
  </ul>
</div>
